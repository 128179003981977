import { CgProfile } from 'react-icons/cg'
import styled from 'styled-components'
import { Colors, FontSize, FontWeight } from '../../../assets'
import { Link } from '../../elements'
import { Div } from '../../elements'

export const Container = styled.div`
    background-color: ${Colors.darkBlue}; 
    padding: 1rem;
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.33);
`

export const Content = styled.div`
    display: flex; 
    justify-content: space-between; 
    font-weight: ${FontWeight.strong};
`

export const Logo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.75rem;
    
    a {
        color: ${Colors.white};
        font-size: ${FontSize.icon}; 
    }

    a:hover {
        color: ${Colors.orange};
    }

    span {
        font-size: ${FontSize.small};
        color: ${Colors.white}
    }
`

export const Navigation = styled.div`
    display: flex; 
    flex-content: space-around;
    align-items: center;
    padding-right: 2rem;
`

export const Icon = styled.span`
    font-size: ${FontSize.normal};
`

export const HelpContainer = styled.div`
    padding-right: 2rem;
`
export const HeaderLink = styled(Link)`
    color: ${Colors.white};
    
    &:hover{
        color: ${Colors.orange};
    }
`

// border-left: 1px solid white;
export const ProfileContainer = styled.div`
    padding-left: 2rem; 
    position: relative;

    &:before {
        background: #4F6B7E;
        width:1px; 
        content:''; 
        position:absolute; 
        left:0px; 
        top:50%; 
        margin-top:-14px; 
        height:28px; 
    }
`

export const ProfileSubContainer = styled.div`
    display: inline-box;
    position: absolute; 
    backgroud-color: ${Colors.orange};
    top: 26px; 
    right: -24px;
    padding-top:29px; 
    padding-right:17px; 
    opacity:1; 
    visibility:visible;  
    z-index: 99;
`

export const ToggleMenuContainer = styled.div `
    background-color: ${Colors.orange};
    min-width: 250px;
    box-sizing: border-box; 
    position: relative; 
    padding: 1.5rem;

    &:before {
        position: absolute; 
        content: ''; 
        right: 0; 
        bottom: 100%; 
        height: 50px; 
        width: 50px; 
        border-top: 22px solid transparent; 
        border-right: 22px solid transparent; 
        border-bottom: 22px solid #ECA100;
        border-left: 22px solid transparent;
    }

    & a:hover {
        color: ${Colors.mainText}
    }
`

export const ProfileIcon = styled(CgProfile)`
    font-size: ${FontSize.icon};
    color: ${Colors.white};

    &:hover {
        color: ${Colors.orange};
    }
`

export const User = styled.h3`
    color: ${Colors.white}
`