import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { Colors } from '../../assets'

export const IconContainer = styled(Col)`
    margin: 0 4rem;

    &:first-of-type {
        margin-left: 0; 
    }
`

export const Title = styled.span`
    margin-top: 1rem;
    text-align: center;
`

export const IconLink = styled(Link) `
    color: ${Colors.mainText};
    display: flex; 
    flex-direction: column; 
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        color: ${Colors.darkBlue};
        text-decoration: none;
    }

    &:hover svg {
        color: ${Colors.darkBlue}
    }
    
    &:hover ${Title} {
        color: ${Colors.mainText}
    }
    
    `
export const Circle = styled.div`
    border: 2px solid ${Colors.lightText};
    border-radius: 50%;
    padding: 2rem;
    display: flex; 
    justify-content: center;
    transition: all 0.3s ease;
    
    &:after {
    }

    ${IconLink}:hover & {
        border: 2px solid ${Colors.darkBlue}
    }
    `