import axios, { AxiosResponse } from 'axios';
import { header, Pagination } from '../useApi';

type DepartmentsGetParams = {
  clinic?: number;
  limit?: number;
  offset?: number;
  search?: string;
  is_active?: boolean;
};

export type Department = {
  id: number;
  external_id: number;
  abbreviation: string;
  description: string;
  specialization_code: string;
  icp: string;
  ns: string;
  workplace_code: string;
  clinic: number;
};

type DepartmentsGetResponse = Pagination & {
  results: Department[];
};

export const DepartmentsApi = () => {
  const options = header();

  const get = async (
    params: DepartmentsGetParams
  ): Promise<AxiosResponse<DepartmentsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/departments`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: number): Promise<AxiosResponse<Department>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/departments/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById };
};
