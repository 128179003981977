import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { Colors } from '../../../../assets';
import { FontSize, FontWeight, Spacing } from '../../../../assets/GlobalStyle/Variables';
import { useAsyncSelectComponent } from './useAsyncSelectComponent';

type SelectProps = {
  id: string;
  name: string;
  label: ReactNode;
  endPoint: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  params?: any;
};

export const AsyncSelectComponent: FC<SelectProps> = (props) => {
  const {
    id,
    name,
    disabled = false,
    label,
    endPoint,
    placeholder = 'Vyhledejte...',
    required = false,
    params = undefined
  } = props;
  const { promiseOptions } = useAsyncSelectComponent({ endPoint, name, params });
  const methods = useFormContext();

  const styles = {
    control: (props: any) => ({
      ...props,
      borderRadius: 0,
      height: 42,
      borderStyle: 'none',
      backgroundColor: 'none'
    })
  };

  return (
    <Wrapper>
      <StyledLabel htmlFor={id}>{label}: </StyledLabel>
      <Controller
        name={name}
        control={methods.control}
        rules={{ required: required }}
        render={({ field }) => (
          <StyledSelectWrapper>
            <StyledAsyncSelect
              {...field}
              isClearable
              styles={styles}
              isDisabled={disabled}
              placeholder={placeholder}
              cacheOptions
              loadOptions={promiseOptions}
            />
            {required && methods.formState.errors?.[name]?.type === 'required' && (
              <span role="alert">Povinné pole</span>
            )}
          </StyledSelectWrapper>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: ${Spacing(8)};
`;

const StyledSelectWrapper = styled.div`
  width: 100%;

  span {
    color: ${Colors.error};
    font-size: ${FontSize.normal};
  }
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  margin: 0px;
  border: 1px solid ${Colors.disabledHover};
  line-height: 30px;
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.grey};
  font-size: ${FontSize.normal};

  &:focus {
    outline: none;
    border: 1px solid ${Colors.disabledHover};
  }
`;

const StyledLabel = styled.label`
  margin: 0;
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  white-space: nowrap;
  min-width: 200px;
`;
