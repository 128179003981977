import React from 'react';
import { HeaderLink, HelpContainer } from './styles';

export const Help: React.FC = () => {
  return (
    <HelpContainer>
      <HeaderLink to="/help">Nápověda</HeaderLink>
    </HelpContainer>
  );
};
