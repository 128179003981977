import React from 'react' 
import styled from 'styled-components';

interface Props {
    children: string; 
    primary?: boolean;
    disabled?: boolean; 
    className?: string;
    type?: "button" | "submit" | "reset" | undefined;
    onClick?: () => void;
}

export const PrimaryButton: React.FC<Props> = ({children, ...props}) => {
    return <StyledButton {...props}>{children}</StyledButton>
}

const StyledButton = styled.button`
  background: #02385d;
  border: 2px solid #02385d;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  min-width: 145px;
  padding: 0 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease !important;
  white-space: nowrap;

  &:hover {
    background: #0164a7;
    border-color: #0164a7;
    color: #fff;
  }
`;