import { useEffect, useState } from "react";

export const useUser = () => {
    const [ user, setUser ] = useState<string> ()
    useEffect (() => {
        getUser ()
    }, [])

    const getUser = () => {
        let stringUser: string | null = localStorage.getItem('user')
        let jsonUser = JSON.parse(stringUser as string)
        const userName = jsonUser?.username
        const name = `${jsonUser?.first_name} ${jsonUser?.last_name}`
        setUser (jsonUser?.first_name.length || jsonUser?.last_name.length ? name : userName)
    }


    return user
}