import React, { useContext } from 'react'
import { Layout, Content, MainContent, MainWrapper } from './styles'
import { RouterManager } from '../../../pages'
import { Header, Sidebar } from '../../index'
import { AuthContext } from '../../../helpers/context_manager/AuthContext'

export const Template:React.FC = () => {
    const context = useContext( AuthContext )
    return (
        <Layout>
            {context?.authToken && <Sidebar />}
            <Content>
                <Header />
                <MainContent>
                    <MainWrapper>
                        <RouterManager />
                    </MainWrapper>                    
                </MainContent>
            </Content>
        </Layout>
    )
}  