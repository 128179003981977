const Colors = {
  darkBlue: '#02385d',
  lightBlue: '#0164A7',
  mainText: '#454552',
  lightText: '#cce0ed',
  black: '#000000',
  orange: '#ECA100',
  white: '#ffffff',
  grey: '#454552',
  lightGrey: '#E5E8EB',
  disabled: '#f1f1f1',
  disabledHover: '#CCD2D7',
  lightBackground: '#cce0ed',
  success: '#D1E7DD',
  error: '#f52235'
};

const FontSize = {
  logo: "32px",
  normal: "16px",
  small: "10px",
  title: "22px",
  subTitle: "18px",
  largeIcon: '45px',
  icon: "34px",
  mdIcon: "24px",
  smallIcon: "17px",
};

const FontWeight = {
  normal: '400',
  strong: '700'
};

const LineHeight = {
  normal: '1',
  higher: '1.5',
  double: '2'
};

const space = 2;
type SpacingValue = number | string;

const Spacing = (...values: SpacingValue[]): string =>
  values.map((value) => (typeof value === 'number' ? `${value * space}px` : value)).join(' ');

export { Colors, FontSize, FontWeight, LineHeight, Spacing };
