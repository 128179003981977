import React, { ReactNode } from 'react';
import { StyledTitle } from './styles';

interface Props {
  children: ReactNode;
  className?: string;
}

export const PageTitle: React.FC<Props> = ({ children, ...props }) => {
  return <StyledTitle {...props}>{children}</StyledTitle>;
};
