import { FC, useEffect } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { BarLoader } from 'react-spinners';
import styled from 'styled-components';
import { Colors, Spacing } from '../../assets/GlobalStyle/Variables';
import { RichTextarea, ControlSelect } from '../../common/components/Form';
import { PopUp } from '../../common/components/PopUp';
import { Link, PrimaryButton, SecondaryButton } from '../../common/elements';
import { useRequestTransportEdit } from './useRequestTransportEdit';

type RequestEditFormProps = {
  setRequestId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const RequestTransportEditForm: FC<RequestEditFormProps> = (props): JSX.Element | null => {
  const { id } = useParams();
  const { setRequestId } = props;
  const { fields, methods, message, loading, canEdit, options, onSubmit, togglePopup } =
    useRequestTransportEdit({
      id
    });

  useEffect(() => {
    setRequestId(id);
  }, [id]);

  return (
    <>
      <BarLoader
        loading={loading}
        color={Colors.lightBlue}
        cssOverride={{ position: 'fixed', width: '95%' }}
      />
      {message && <PopUp type={message.type} message={message.message} toggle={togglePopup} />}
      {!canEdit && (
        <Alert className="mt-1" variant="warning">
          Žádanku již nelze editovat.
        </Alert>
      )}
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col lg={6} md={12}>
              <ControlSelect {...fields.subtype} options={options.transportType} />
            </Col>
            <Col lg={6} md={12}>
              <ControlSelect {...fields.end} options={options.transportDestination} />
            </Col>
            <Col lg={12}>
              <RichTextarea {...fields.text} />
            </Col>
          </Row>
          <StyledActionSection>
            {canEdit && <PrimaryButton type="submit">Uložit žádanku</PrimaryButton>}
            <Link to="/request-transport-listing">
              <SecondaryButton type="button">Zpět na seznam žádanek</SecondaryButton>
            </Link>
          </StyledActionSection>
        </StyledForm>
      </FormProvider>
    </>
  );
};

const StyledActionSection = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing(20)};

  button {
    margin: 0 5px 0 5px;
  }
`;

const StyledForm = styled(Form)`
  padding-top: 1.5rem;
`;
