import { useState } from 'react';

export const SidebarLogic = () => {
  const [hide, setHide] = useState<boolean>(true);

  const toggle = (): void => {
    setHide((prevState) => {
      return !prevState;
    });
  };

  return { hide, toggle };
};
