import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Requisition } from '../../api/requisitionsApi';
import { PageTitle } from '../../common';
import { RequestTransportNewForm } from './RequestTransportNewForm';

export const RequestTransportNew: FC = (): JSX.Element => {
  return (
    <>
      <PageTitle>Nová žádanka na přepravu</PageTitle>
      <Row>
        <Col xl={8} lg={10} md={12}>
          <RequestTransportNewForm />
        </Col>
      </Row>
    </>
  );
};

type ErrorMessageProps = {
  requisitions: Requisition[];
};

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { requisitions } = props;
  return (
    <div>
      <p>Pacient má již založenou jinou žádanku.</p>
      {requisitions.map((item) => (
        <div className="mb-2">
          <Link to={`/request-edit/${item.id}`}>
            <FontAwesomeIcon icon={faFile} className="mr-2" /> Žádanka ID {item.id}
          </Link>
        </div>
      ))}
    </div>
  );
};
