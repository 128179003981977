import React from 'react';
import { Icon, ArrowLeft, ArrowRight, ArrowContainer } from '../../styles';

interface HeaderIconProps {
  hide: boolean;
  toggle: () => void;
}

export const HeaderIcon: React.FC<HeaderIconProps> = ({ hide, toggle }) => {
  return (
    <>
      <Icon onClick={toggle} />
      <ArrowContainer>{hide ? <ArrowRight /> : <ArrowLeft />}</ArrowContainer>
    </>
  );
};
