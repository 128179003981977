import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { BarLoader } from 'react-spinners';
import styled from 'styled-components';
import { Colors, Spacing } from '../../assets/GlobalStyle/Variables';
import { ControlSelect, RichTextarea } from '../../common/components/Form';
import { PopUp } from '../../common/components/PopUp';
import { PrimaryButton } from '../../common/elements';
import { useRequestTransportNew } from './useRequestTransportNew';

export const RequestTransportNewForm: FC = (): JSX.Element => {
  const { fields, methods, message, loading, options, onSubmit, togglePopup } =
    useRequestTransportNew();
  return (
    <>
      <BarLoader
        loading={loading}
        color={Colors.lightBlue}
        cssOverride={{ position: 'fixed', width: '95%' }}
      />
      {message && <PopUp type={message.type} message={message.message} toggle={togglePopup} />}
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col lg={6} md={12}>
              <ControlSelect {...fields.subtype} options={options.transportType} />
            </Col>
            <Col lg={6} md={12}>
              <ControlSelect {...fields.end} options={options.transportDestination} />
            </Col>
            <Col lg={12}>
              <RichTextarea {...fields.text} />
            </Col>
          </Row>
          <StyledActionSection>
            <PrimaryButton type="submit" primary>
              Odeslat žádanku
            </PrimaryButton>
          </StyledActionSection>
        </StyledForm>
      </FormProvider>
    </>
  );
};

const StyledActionSection = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing(20)};

  button {
    margin: 0 5px 0 5px;
  }
`;

const StyledForm = styled(Form)`
  padding-top: 1.5rem;
`;
