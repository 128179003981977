import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney, faGear, faShuttleVan } from '@fortawesome/free-solid-svg-icons';
import {
  faFile,
  faFileLines,
  faBarChart,
  faFilePowerpoint
} from '@fortawesome/free-regular-svg-icons';
import { FullNavigation } from '../../styles';
import { ListItem, UList, Link, Span } from '../../../../elements';

interface NavigationProps {
  hide: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ hide }) => {
  return (
    <>
      {hide ? (
        <UList>
          {collapsedNavigation.map((nav, key): JSX.Element => {
            return <ListItem key={key}>{nav}</ListItem>;
          })}
        </UList>
      ) : (
        <FullNavigation flexColumn>
          <UList>
            {navigation.map((nav, key): JSX.Element => {
              return <ListItem key={key}>{nav}</ListItem>;
            })}
          </UList>
        </FullNavigation>
      )}
    </>
  );
};

const collapsedNavigation: JSX.Element[] = [
  <Link to="/">
    <FontAwesomeIcon icon={faHouseChimney} />
  </Link>,
  <Link to="/request-new">
    <FontAwesomeIcon icon={faFile} />
  </Link>,
  <Link to="/request-transport-new">
    <FontAwesomeIcon icon={faShuttleVan} />
  </Link>,
  <Link to="/request-listing">
    <FontAwesomeIcon icon={faFileLines} />
  </Link>,
  <Link to="/request-transport-listing">
    <FontAwesomeIcon icon={faFilePowerpoint} />
  </Link>,
  <Link to="/reports">
    <FontAwesomeIcon icon={faBarChart} />
  </Link>,
  <Link to="/settings">
    <FontAwesomeIcon icon={faGear} />
  </Link>
];

const navigation: JSX.Element[] = [
  <Link to="/">
    <FontAwesomeIcon icon={faHouseChimney} />
    <Span>Úvodní obrazovka</Span>
  </Link>,
  <Link to="/request-new">
    <FontAwesomeIcon icon={faFile} />
    <Span>Nová žádanka KF</Span>
  </Link>,
  <Link to="/request-transport-new">
    <FontAwesomeIcon icon={faShuttleVan} />
    <Span>Nová žádanka Doprava</Span>
  </Link>,
  <Link to="/request-listing">
    <FontAwesomeIcon icon={faFileLines} />
    <Span>Pacientské žádanky</Span>
  </Link>,
  <Link to="/request-transport-listing">
    <FontAwesomeIcon icon={faFilePowerpoint} />
    <Span>Žádanky na přepravu</Span>
  </Link>,
  <Link to="/reports">
    <FontAwesomeIcon icon={faBarChart} />
    <Span>Reporty</Span>
  </Link>,
  <Link to="/settings">
    <FontAwesomeIcon icon={faGear} />
    <Span>Nastavení</Span>
  </Link>
];
