import React from 'react' 
import { StyledWrapper } from './styles'

interface Props {
    children: JSX.Element | JSX.Element[]; 
    className?: string; 
    flexColumn?: boolean;
}

export const Wrapper: React.FC<Props> = ({children, ...props}) => {
    return <StyledWrapper  {...props}>{children}</StyledWrapper>
}