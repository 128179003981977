import { AxiosResponse } from 'axios'
import { useApi } from '../../../api/useApi'
import { useContext } from 'react'
import { AuthContext } from '../../../helpers/context_manager/AuthContext'

interface BasicLoginProps {
    username: string, 
    password: string, 
}

export const useLoginUser = () => {
    const { login, user } = useApi()
    const context = useContext (AuthContext)
    //decide which kind of sign in to use 
    //signin user
    //handle errors 
    const loginBasic = async (data : BasicLoginProps) => {
        try {
            const response = await login.basicLogin(data) 
            await handleResponse (response)
        }   
        catch (e) { 
            return handleError (e)
        }
    }

    const loginKerberos = async() => {
        try {
            const response = await login.kerberosLogin ()
            const data = await response.json()
            localStorage.setItem('authToken', data.token)
            //add token to authentification token
            context?.setAuthToken( data.token )
            //laod user and save to local storage
            await loadUser ()
        }
        catch (e) {
            return handleError (e)
        }
    }

    const handleResponse = async (response: any) => {
        //save auth token to local storage
        localStorage.setItem('authToken', response.data.token)
        //add token to authentification token
        context?.setAuthToken( response.data.token )
        //laod user and save to local storage
        await loadUser ()
    }
    
    const loadUser = async () => {
        //laod current user
        const response = await user.getUser()
        //save user to local storage
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    const handleError = (error:any) => {
        console.log(error)
        if (error.response.status === 401 ) {
            console.log('Špatné uživatelské jméno nebo heslo', error.response.statusText)
            return Promise.reject("Špatné uživatelské jméno nebo heslo")
        } 
        else if ( error.resposnse.status === 403) {
            console.log ('Tento uživatel nemá oprávnění ke vstupu', error.resposnse.statusText)
            return Promise.reject('Tento uživatel nemá oprávnění ke vstupu')
        }
        else {
            console.log('Chyba při přihlašování uživatele')
            return Promise.reject('Chyba při přihlašování uživatele')
        }
    }

    return {loginBasic, loginKerberos}
}