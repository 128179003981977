import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageTitle } from '../../common';
import { RequestEditForm } from './RequestEditForm';

export const RequestEdit: React.FC = () => {
  const [requestId, setRequestId] = useState<string | undefined>();
  return (
    <>
      <PageTitle>Editace žádanky / ID žádanky - {requestId}</PageTitle>
      <Row>
        <Col xl={8} lg={10} md={12}>
          <RequestEditForm setRequestId={setRequestId} />
        </Col>
      </Row>
    </>
  );
};
