import { ReactNode, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useApi } from '../../api/useApi';
import { PopUpType } from '../../common/components/PopUp';
import { RequisitionsPostType } from '../../api/requisitionsApi';

type FormValues = {
  subtype: Option;
  end: Option;
  text: string;
};

type Option = {
  value: string | number;
  label: string;
};

type Options = {
  transportType: Option[];
  transportDestination: Option[];
};

export const useRequestTransportNew = () => {
  const [message, setMessage] = useState<{ type: PopUpType; message: ReactNode } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>({ transportDestination: [], transportType: [] });
  const api = useApi();

  const methods = useForm({
    defaultValues: {
      subtype: { label: '', value: '' },
      end: { label: '', value: '' },
      text: ''
    }
  });

  useEffect(() => {
    getRequisitionType();
    getLocation();
  }, []);

  const getRequisitionType = async () => {
    const response = await api.requisitions.getRequisitionSubtypeCodeList({ type: 'idoprava' });
    if (response.status === 200) {
      setOptions((prev) => ({
        ...prev,
        transportType: response.data.results.map((item) => ({ label: item.name, value: item.id }))
      }));
    }
  };

  const getLocation = async () => {
    const response = await api.locations.get();
    if (response.status === 200) {
      setOptions((prev) => ({
        ...prev,
        transportDestination: response.data.results.map((item) => ({
          label: item.name,
          value: item.id
        }))
      }));
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    const applicant = await api.user.getUser();

    const mData: RequisitionsPostType = {
      ...data,
      state: 'created',
      type: 'idoprava',
      applicant: applicant.data.id,
      subtype: data.subtype.value,
      end: data.end.value
    };
    try {
      const response = await api.requisitions.post(mData);

      if (response.status !== 201) {
        setMessage({ type: 'error', message: 'Žádanku se nepodařilo vytvořit.' });
        setLoading(false);
      }

      methods.reset();
      setLoading(false);
      setMessage({ type: 'success', message: 'Žádanka byla úspěšně vytvořena.' });
    } catch (error) {
      setMessage({ type: 'error', message: 'Žádanku se nepodařilo vytvořit.' });
      setLoading(false);
    }
  };

  const togglePopup = () => {
    setMessage(undefined);
  };

  const fields = {
    subtype: {
      id: 'subtype',
      name: 'subtype',
      label: 'Typ přepravy'
    },
    end: {
      id: 'end',
      name: 'end',
      label: 'Cíl přepravy'
    },
    text: {
      id: 'text',
      name: 'text',
      label: 'Text žádosti'
    }
  };

  return {
    methods,
    fields,
    message,
    loading,
    options,
    onSubmit,
    togglePopup
  };
};
