import styled from 'styled-components';
import { Colors, FontSize } from '../../../assets';

export const StyledTitle = styled.h1`
  color: ${Colors.darkBlue};
  font-size: ${FontSize.title};
  border-bottom: 1px solid ${Colors.lightGrey};
  padding-bottom: 0.5rem;
  width: 100%;
`;
