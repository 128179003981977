import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Template } from './common';
import { GlobalStyle } from './assets/GlobalStyle/GlobalStyle'
import { AuthContext } from './helpers/context_manager/AuthContext';

export const App: React.FC = () => {
  const [ authToken, setAuthToken ] = useState<string | null>(localStorage.getItem('authToken'))

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken}}>
      <BrowserRouter>
       <GlobalStyle />
       <Template />
      </BrowserRouter>
    </AuthContext.Provider>
  )
}
