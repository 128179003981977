import { FC } from "react";
import { PageTitle } from "../../common";
import { ReportsNewForm } from "./components/ReportsForm";

export const Reports:FC = (): JSX.Element => {
  return (
    <>
      <PageTitle>Reporty</PageTitle>
      <div className="row">
        <div className="col-xl-8 col-lg-10 col-md-12">
          <ReportsNewForm />
        </div>
      </div>
    </>
  );
};
