import React from 'react' 
import { StyledSpan } from './styles' 

interface Props {
    children: React.ReactNode;
    clasName?: string; 
}

export const Span: React.FC<Props> = ({children, ...props}) => {
    return <StyledSpan {...props}>{children}</StyledSpan>
}
