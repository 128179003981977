import axios, { AxiosResponse } from 'axios';
import { Clinic } from '../clinicsApi/ClinicsApi';
import { Department } from '../departmentsApi';
import { Diagnose } from '../diagnosesApi/DiagnosesApi';
import { Patient } from '../patientsApi';
import { Person } from '../personsApi';
import { header, Pagination } from '../useApi';
import { Locations } from '../locationsApi/LocationsApi';

export type RequisitionsTypes = 'idoprava' | 'ipharm';
export type RequisitionsSubTypes =
  | 'idoprava_couriers'
  | 'idoprava_drugs'
  | 'idoprava_gases'
  | 'idoprava_mtz'
  | 'idoprava_szm'
  | 'idoprava_tools'
  | 'ipahrm_adverse_effect'
  | 'ipahrm_antibiotic_stewardship'
  | 'ipharm_conciliation';
export type RequisitionsSubType = {
  id: number;
  reference_id: number;
  name: string;
  type: RequisitionsTypes;
  subtype: string;
  description: string;
  is_active: boolean;
};
export type RequisitionsState =
  | 'created'
  | 'pending'
  | 'canceled'
  | 'refused'
  | 'solved'
  | 'locked';

export type RequisitionsGetParams = {
  id?: number;
  is_synced?: boolean;
  limit: number;
  offset: number;
  page: number;
  search?: string;
  type?: RequisitionsTypes;
  o?: string;
};

export type RequisitionsSimpleGetParams = {
  patient__external_id?: string;
  patient__birth_number?: string;
};

export type RequisitionSubtypeCodeListParams = {
  is_active?: boolean;
  limit?: number;
  offset?: number;
  reference_id?: number;
  search?: string;
  subtype?: RequisitionsSubTypes;
  type?: RequisitionsTypes;
};

export type Requisition = {
  id: number;
  patient: Patient;
  answer: string;
  applicant: Person;
  solver: Person;
  clinic: Clinic;
  diagnosis: Diagnose;
  department: Department;
  type: RequisitionsTypes;
  subtype: RequisitionsSubType | null;
  state: RequisitionsState;
  text: string;
  file: string;
  created_at: string;
  updated_at: string;
  is_synced: boolean;
  synced_at: Date;
  end: Locations;
};

type RequisitionsGetResponse = Pagination & {
  results: Requisition[];
};

type RequisitionSubtypeCodeListResponse = Pagination & {
  results: RequisitionsSubType[];
};

export type RequisitionsPostType = {
  state?: RequisitionsState;
  type?: string;
  subtype?: number | string;
  applicant?: number;
  text?: string;
  file?: string;
  patient?: number;
  clinic?: number;
  department?: number;
  diagnosis?: number;
  end?: string | number;
};

type RequisitionsUpdateType = RequisitionsPostType;

export const RequisitionsApi = () => {
  const options = header();

  const get = async (
    params: RequisitionsGetParams | RequisitionsSimpleGetParams
  ): Promise<AxiosResponse<RequisitionsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/requisitions/`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: string): Promise<AxiosResponse<Requisition>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/requisitions/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const post = async (data: RequisitionsPostType): Promise<AxiosResponse<Requisition>> => {
    try {
      const response = await axios.post(process.env.REACT_APP_ROOT_API + '/requisitions/', data, {
        ...options
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const update = async (
    data: RequisitionsUpdateType,
    id: string
  ): Promise<AxiosResponse<Requisition>> => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_API}/requisitions/${id}/`,
        data,
        { ...options }
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getRequisitionSubtypeCodeList = async (
    params: RequisitionSubtypeCodeListParams
  ): Promise<AxiosResponse<RequisitionSubtypeCodeListResponse>> => {
    try {
      const response = await axios.get(process.env.REACT_APP_ROOT_API + `/requisition-types/`, {
        ...options,
        params: params
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById, post, update, getRequisitionSubtypeCodeList };
};
