import React from 'react' 
import { HeaderLink, ProfileContainer, ProfileIcon, ProfileSubContainer, ToggleMenuContainer, User } from './styles'
import { useHover } from './useHover'
import { useUser } from './useUser'

export const Profile:React.FC = () => {
    const { hide, onHover } = useHover ()
    const user = useUser()

    return (
        <ProfileContainer>
            <ProfileIcon onMouseEnter={onHover} />
            { hide &&
                <ProfileSubContainer onMouseLeave={onHover}>
                    <ToggleMenuContainer>
                        <User className='mb-3'>{user}</User>
                        <HeaderLink to='/signin'>Odhlášení</HeaderLink>
                    </ToggleMenuContainer>
                </ProfileSubContainer>
            }
        </ProfileContainer>
    ) 
} 