import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Colors } from '../../../assets';
import { FontSize, FontWeight, Spacing } from '../../../assets/GlobalStyle/Variables';

type TextareaProps = {
  id: string;
  name: string;
  label: ReactNode;
  disabled?: boolean;
  rows: number;
};

export const Textarea: FC<TextareaProps> = (props) => {
  const { id, name, disabled = false, label, rows } = props;

  const methods = useFormContext();

  return (
    <StyledTextareaWrapper>
      <StyledLabel htmlFor={id}>{label}: </StyledLabel>
      <StyledTextarea id={id} disabled={disabled} rows={rows} {...methods.register(name)} />
    </StyledTextareaWrapper>
  );
};

const StyledTextareaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${Spacing(8)};
`;

const StyledTextarea = styled.textarea`
  margin: 0px;
  padding: 0 ${Spacing(8)};
  border: 1px solid ${Colors.disabledHover};
  line-height: 40px;
  height: auto;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.grey};
  font-size: ${FontSize.normal};

  &:focus {
    outline: none;
    border: 1px solid ${Colors.disabledHover};
  }
`;

const StyledLabel = styled.label`
  margin: 0;
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  min-width: 200px;
`;
