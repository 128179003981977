import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useApi } from '../../api/useApi';
import { PopUpType } from '../../common/components/PopUp';

type FormValues = {
  last_name: string;
  first_name: string;
  patientExternalId: string;
  birth_number: string;
  clinic: Option;
  diagnoses: Option;
  department: Option;
  text: string;
  answer: string;
  file?: any;
  type: Option;
};

type Option = {
  value: string;
  label: string;
};

type UseRequestEditProps = {
  id: string | undefined;
};

export const useRequestEdit = (props: UseRequestEditProps) => {
  const { id } = props;
  const [message, setMessage] = useState<{ type: PopUpType; message: string } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [canEdit, setCanEdit] = useState<boolean>(true);

  const api = useApi();

  useEffect(() => {
    onLoadData();
  }, [id]);

  const methods = useForm({
    defaultValues: {
      last_name: '',
      first_name: '',
      patientExternalId: '',
      patientId: '',
      birth_number: '',
      clinic: { value: '', label: 'Vyhledejte' },
      diagnoses: { value: '', label: 'Vyhledejte' },
      department: { value: '', label: 'Vyhledejte' },
      text: '',
      type: { value: '', label: 'Vyhledejte' },
      answer: ''
    }
  });

  const onLoadData = async () => {
    setLoading(true);

    try {
      const response = await api.requisitions.getOneById(id as string);

      if (response.status === 200) {
        const data = response.data;
        methods.setValue('last_name', data.patient.last_name);
        methods.setValue('first_name', data.patient.first_name);
        methods.setValue('patientExternalId', data.patient.external_id);
        methods.setValue('birth_number', data.patient.birth_number);
        methods.setValue('type', { value: data.type, label: data.type });
        methods.setValue('answer', data.answer === 'null' || !data.answer ? '' : data.answer);
        methods.setValue('text', data.text);
        if (data.clinic) {
          methods.setValue('clinic', {
            value: data.clinic.id.toString(),
            label: data.clinic.description
          });
        }

        if (data.diagnosis) {
          methods.setValue('diagnoses', {
            value: data.diagnosis.id.toString(),
            label: data.diagnosis.name
          });
        }

        if (data.department) {
          methods.setValue('department', {
            value: data.department.id.toString(),
            label: data.department.description
          });
        }
        setFileUrl(data.file);
        setCanEdit(data.state !== 'locked' && data.state !== 'solved' && data.state !== 'refused');
        setTimeout(() => {
          setLoading(false);
        }, 1050);
      }
    } catch (error) {
      setLoading(false);
      setMessage({ type: 'error', message: 'Žádanka nebyla nalezena.' });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    setLoading(true);

    try {
      const response = await api.requisitions.update(mapedData(data), id as string);

      if (response.status === 200) {
        setLoading(false);
        setMessage({ type: 'success', message: 'Žádanka byla úspěšně uložena.' });
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: 'error',
        message: 'Žádanku se nepodařilo uložit, zkuste to později, prosím.'
      });
    }
  };

  const clinicWatch: any = useWatch({ control: methods.control, name: 'clinic' });

  useEffect(() => {
    if (!clinicWatch) {
      methods.setValue('department', { value: '', label: 'Vyhledejte' });
    }
  }, [clinicWatch]);

  const options = {
    type: [
      { value: 'ipharm', label: 'Ipharm' },
      { value: 'delivery', label: 'iDoprava' }
    ]
  };

  const fields = {
    last_name: {
      id: 'last_name',
      name: 'last_name',
      label: 'Příjmení pacienta'
    },
    first_name: {
      id: 'first_name',
      name: 'first_name',
      label: 'Jméno pacienta'
    },
    patientExternalId: {
      type: 'number',
      id: 'patientExternalId',
      name: 'patientExternalId',
      label: 'ID pacienta',
      disabled: true
    },
    birth_number: {
      type: 'number',
      id: 'birth_number',
      name: 'birth_number',
      label: 'RČ pacienta',
      disabled: true
    },
    clinic: {
      id: 'clinic',
      name: 'clinic',
      label: 'Klinika',
      endPoint: 'clinics',
      required: true
    },
    diagnoses: {
      id: 'diagnoses',
      name: 'diagnoses',
      label: 'Diagnóza',
      endPoint: 'diagnoses',
      required: true
    },
    department: {
      id: 'department',
      name: 'department',
      label: 'Oddělení',
      endPoint: 'departments',
      required: true
    },
    type: {
      id: 'type',
      name: 'type',
      label: 'Typ žádanky',
      disabled: true,
      options: options.type
    },
    text: {
      id: 'text',
      name: 'text',
      label: 'Text žádosti',
      height: '150px'
    },
    answer: {
      id: 'answer',
      name: 'answer',
      label: 'Reakce klinického farmaceuta',
      disabled: true,
      height: '150px'
    },
    file: {
      id: 'file',
      name: 'file',
      label: 'Přiložit soubor',
      fileUrl: fileUrl,
      accept:
        'application/pdf,application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      acceptDescription: 'Pouze soubory typu pdf, csv, xlsx, docx. Velikost max 2 MB'
    }
  };

  const mapedData = (data: FormValues): any => {
    const formData = new FormData();

    const isBlob = typeof data.file === 'string' || data.file instanceof String;

    if (data.file !== null && data.file[0] && !isBlob) {
      formData.append('file', data.file[0], data.file[0].name);
    }
    formData.append('text', data.text);
    formData.append('answer', data.answer);
    formData.append('clinic', data.clinic?.value);
    formData.append('diagnosis', data.diagnoses?.value);
    formData.append('department', data.department?.value);

    return formData;
  };

  const togglePopup = () => {
    setMessage(undefined);
  };

  return {
    loading,
    message,
    methods,
    fields,
    fileUrl,
    canEdit,
    clinicWatch,
    togglePopup,
    onSubmit
  };
};
