import React from 'react';
import { Span } from '../../../../elements';
import { dateHelper } from '../../../../../helpers';

export const Footer: React.FC = () => {
  return (
    <>
      <Span>iŽádanky</Span>
      <Span>0.0.22</Span>
      <Span>{`(${dateHelper.getCurrentDDMMYYDate()})`}</Span>
    </>
  );
};
