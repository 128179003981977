import React from 'react' 
import { StyledLink } from './styles';

interface Props {
    children: React.ReactNode; 
    to: string;
    className?: string; 
    onClick?: () => void
}

export const Link: React.FC<Props> = ({children, ...props}) => {
    return <StyledLink {...props}>{children}</StyledLink>
}