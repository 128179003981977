export interface UserInterface {}

export interface FormProps {
  username: string;
  password: string;
}

export const DefaultValues: FormProps = {
  username: '',
  password: ''
};
