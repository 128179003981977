import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Patient } from '../../api/patientsApi';
import { RequisitionsSimpleGetParams } from '../../api/requisitionsApi';
import { useApi } from '../../api/useApi';
import { PopUpType } from '../../common/components/PopUp';
import { ErrorMessage } from './RequestNew';

type FormValues = {
  last_name: string;
  first_name: string;
  patientExternalId: string;
  patientId: string;
  birth_number: string;
  clinic: Option | undefined;
  diagnoses: Option | undefined;
  department: Option | undefined;
  text: string;
  file?: any;
};

type Option = {
  value: string;
  label: string;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useRequestNew = () => {
  const query = useQuery();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [message, setMessage] = useState<{ type: PopUpType; message: ReactNode } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();

  const methods = useForm({
    defaultValues: {
      last_name: '',
      first_name: '',
      patientExternalId: '',
      patientId: '',
      birth_number: query.get('rc') || '',
      clinic: undefined,
      department: undefined,
      diagnoses: undefined,
      text: ''
    }
  });

  useEffect(() => {
    const rodnecislo = query.get('rc');
    const klinika = query.get('klin');

    if (rodnecislo) {
      methods.setValue('birth_number', rodnecislo);
      autoLoadPatient(rodnecislo);
    }

    if (klinika) {
      getClinic(klinika);
    }
  }, [query]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    const applicant = await api.user.getUser();

    try {
      const response = await api.requisitions.post(mapedData(data, applicant.data.id));

      if (response.status !== 201) {
        setMessage({ type: 'error', message: 'Žádanku se nepodařilo vytvořit.' });
        setLoading(false);
      }

      methods.reset();
      setLoading(false);
      setCanSubmit(false);
      setMessage({ type: 'success', message: 'Žádanka byla úspěšně vytvořena.' });
    } catch (error) {
      setMessage({ type: 'error', message: 'Žádanku se nepodařilo vytvořit.' });
      setLoading(false);
    }
  };

  const getPatient = async (taskId: string) => {
    const response = await api.tasks.getOneById(taskId);
    if (response.data.state === 'SUCCESS' && response.data.result.data) {
      const patient = response.data.result.data;
      setFormValues(patient);
      setCanSubmit(true);
      setLoading(false);
      return;
    } else if (
      response.data.state === 'SUCCESS' &&
      response.data.result.error === 'PatientNotFound'
    ) {
      setMessage({ type: 'error', message: 'Pacient nebyl nalezen.' });
      setLoading(false);
      return;
    } else if (response.data.state === 'FAILURE') {
      setMessage({ type: 'error', message: 'Nastala chyba, zkuste to později, prosím.' });
      setLoading(false);
      return;
    }
    setTimeout(() => {
      getPatient(taskId);
    }, 2000);
  };

  const onLoadPatient: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);

    const params: RequisitionsSimpleGetParams = {
      patient__external_id: data.patientExternalId,
      patient__birth_number: data.birth_number
    };

    const patientRequisitions = await api.requisitions.get(params);

    const openedRequisitions = patientRequisitions.data.results.filter(
      (item) => item.state === 'created' || item.state === 'pending'
    );

    if (openedRequisitions.length !== 0) {
      setMessage({ type: 'info', message: <ErrorMessage requisitions={openedRequisitions} /> });
      setLoading(false);
      return;
    }

    const task = await api.patients.post({
      external_id: data.patientExternalId,
      birth_number: data.birth_number
    });

    await getPatient(task.data.task_id);
  };
  const autoLoadPatient = async (birthNumber: string) => {
    const patientRequisitions = await api.requisitions.get({ patient__birth_number: birthNumber });

    const openedRequisitions = patientRequisitions.data.results.filter(
      (item) => item.state === 'created' || item.state === 'pending'
    );

    if (openedRequisitions.length !== 0) {
      setMessage({ type: 'info', message: <ErrorMessage requisitions={openedRequisitions} /> });
      setLoading(false);
      return;
    }

    const task = await api.patients.post({
      birth_number: birthNumber
    });

    await getPatient(task.data.task_id);
  };

  const getClinic = async (alias: string) => {
    const response = await api.clinics.getOneByAlias(alias);
    if (response.status === 200) {
      methods.setValue('clinic', {
        label: response.data.results[0].description,
        value: response.data.results[0].id
      } as any);
    }
  };

  const togglePopup = () => {
    setMessage(undefined);
  };

  const handleReset = () => {
    methods.reset();
    setCanSubmit(false);
  };

  const clinicWatch: any = useWatch({ control: methods.control, name: 'clinic' });
  const patientExternalIdWatch: any = useWatch({
    control: methods.control,
    name: 'patientExternalId'
  });
  const birth_numberWatch: any = useWatch({ control: methods.control, name: 'birth_number' });

  const canLoadPatient = patientExternalIdWatch.length !== 0 || birth_numberWatch.length !== 0;

  const fields = {
    last_name: {
      id: 'last_name',
      name: 'last_name',
      label: 'Příjmení pacienta'
    },
    first_name: {
      id: 'first_name',
      name: 'first_name',
      label: 'Jméno pacienta'
    },
    patientExternalId: {
      type: 'number',
      id: 'patientExternalId',
      name: 'patientExternalId',
      label: 'ID pacienta'
    },
    birth_number: {
      type: 'number',
      id: 'birth_number',
      name: 'birth_number',
      label: 'RČ pacienta'
    },
    clinic: {
      id: 'clinic',
      name: 'clinic',
      label: 'Klinika',
      endPoint: 'clinics',
      required: true
    },
    department: {
      id: 'department',
      name: 'department',
      label: 'Oddělení ',
      endPoint: 'departments',
      required: true
    },
    diagnoses: {
      id: 'diagnoses',
      name: 'diagnoses',
      label: 'Diagnóza',
      endPoint: 'diagnoses',
      required: true
    },
    text: {
      id: 'text',
      name: 'text',
      label: 'Text žádosti:'
    },
    file: {
      id: 'file',
      name: 'file',
      label: 'Přiložit soubor',
      accept:
        'application/pdf,application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      acceptDescription: 'Pouze soubory typu pdf, csv, xlsx, docx. Velikost max 2 MB'
    }
  };

  const mapedData = (data: FormValues, applicant: number): any => {
    const formData = new FormData();

    const isBlob = typeof data.file === 'string' || data.file instanceof String;

    if (data.file !== null && data.file[0] && !isBlob) {
      formData.append('file', data.file[0], data.file[0].name);
    }
    formData.append('type', 'ipharm');
    formData.append('text', data.text);
    formData.append('patient', data.patientId);
    formData.append('clinic', data.clinic?.value as string);
    formData.append('applicant', applicant.toString());
    formData.append('diagnosis', data.diagnoses?.value as string);
    formData.append('department', data.department?.value as string);

    return formData;
  };

  const setFormValues = (patient: Patient) => {
    methods.setValue('first_name', patient.first_name);
    methods.setValue('last_name', patient.last_name);
    methods.setValue('birth_number', patient.birth_number);
    methods.setValue('patientExternalId', patient.external_id);
    methods.setValue('patientId', patient.id as string);
  };

  return {
    methods,
    fields,
    message,
    loading,
    clinicWatch,
    canSubmit,
    canLoadPatient,
    onSubmit,
    onLoadPatient,
    togglePopup,
    handleReset
  };
};
