import React from 'react' 
import { StyledUList } from './styles'

interface UListProps {
    children: JSX.Element | JSX.Element[]
    className?: string
}

export const UList: React.FC<UListProps> = ({children, ...props}) => {
    return <StyledUList {...props}>{children}</StyledUList>
}