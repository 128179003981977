import { SubmitHandler,useForm, FieldErrors } from 'react-hook-form'
import { DefaultValues, FormProps } from '../models'
import { UseFormReturn } from 'react-hook-form'
import { useLoginUser } from './useLoginUser'
import { useNavigate } from 'react-router'

interface FieldProps {
    username: {
        id: string, 
        name: string, 
        label?: string, 
    }, 
    password: {
        id: string, 
        name: string, 
        label?: string,
    }
}

interface Return { 
    methods: UseFormReturn<FormProps, any>, 
    handleSubmit: SubmitHandler<FormProps>, 
    fields: FieldProps, 
    errors: FieldErrors,
}

export const useSigninForm = ():Return => {
    const { loginBasic } = useLoginUser ()
    const navigate = useNavigate ()
    
    const methods = useForm<FormProps> ({
        defaultValues: DefaultValues
    })

    const { formState: {errors}} = methods

    const handleSubmit: SubmitHandler<FormProps> = async (data) => {
        try {
            await loginBasic (data) 
            navigate('/')
        }
        catch (e){
            console.error (e)
            methods.setError('username', {type: 'api', message: e as string})
        }
    } 
    
    let fields = {
        username: {
            id: 'username', 
            name: 'username',
        }, 
        password: {
            id: 'password', 
            name: 'password', 
        }
    }
    
    return { methods, fields, handleSubmit, errors }
}