import axios, { AxiosResponse } from 'axios';
import { header, Pagination } from '../useApi';

type ReportsGetParams = {
  limit?: number;
  offset?: number;
};

export type Report = {
  id: number;
  name: string;
  description: string;
  file_name: string;
  order: number;
  frequency: string;
  formats: string[];
};

type ReportsGetResponse = Pagination & {
  results: [];
};

type ReportsVariablesParams = {
  limit?: number;
  offset: number;
};

type ReportVariable = {
  id: number;
  name: string;
  description: string;
  variable_type: string;
  value: string;
  order: number;
};

type ReportVariableResponse = Pagination & {
  results: ReportVariable[];
};

export const ReportsApi = () => {

  const options = header();

  const get = async (params: ReportsGetParams): Promise<AxiosResponse<ReportsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/reports`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const generate = async (id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_API}/reports/${id}/generate/`,
        {
          ...options
        }
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const variables = async (
    id: number,
    params: ReportsVariablesParams
  ): Promise<AxiosResponse<ReportVariableResponse>> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_API}/reports/${id}/variables/`,
        {
          ...options,
          params: {
            ...params
          }
        }
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, generate, variables };
};
