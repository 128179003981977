import React from 'react' 
import { StyledContainer } from './styles'

interface Props {
    children: JSX.Element | JSX.Element[],
    className?: string
    flexColumn?: boolean
}

export const Container: React.FC<Props> = ({children, ...props}) => {
    return <StyledContainer {...props}>{children}</StyledContainer>
}

