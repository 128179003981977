import { FC, useEffect, useState } from 'react';
import { StyledSelect, StyledTableTd } from './styles';

type Option = {
  id: number | string;
  name: string;
};

type TableFilterSelectProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  filter: any;
  field: string;
  options: Option[];
};

export const TableFilterSelect: FC<TableFilterSelectProps> = (props) => {
  const { onChange, filter, field, options } = props;

  const [option, setOption] = useState<Option | undefined>({ id: 0, name: 'Vyberte' });

  useEffect(() => {
    if (filter[field] && options.length !== 0) {
      setOption(options.find((item: Option) => item.id === filter[field]));
    }
  }, [options]);

  return (
    <StyledTableTd role="cell">
      <StyledSelect name={field} onChange={onChange}>
        <option value={option?.id}>{option?.name}</option>
        {options.map((item, key) => (
          <option key={key} className={item.id === option?.id ? 'd-none' : ''} value={item.id}>
            {item.name}
          </option>
        ))}
      </StyledSelect>
    </StyledTableTd>
  );
};
