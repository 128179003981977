import { useApi } from '../../../../api/useApi';
import { parseDataForSelect } from '../../../../helpers/help_functions/parseDataForSelect';

type UseAsyncSelectComponentProps = {
  endPoint: string;
  name: string;
  params: any;
};

export const useAsyncSelectComponent = (props: UseAsyncSelectComponentProps) => {
  const { endPoint, name, params } = props;
  const api = useApi();
  const filterOptions = async (inputValue: string) => {
    let response = await api.search.liveSearch(endPoint, inputValue, params);
    let data = response.data.results;
    if (data.length === 0) {
      return [{ label: 'Záznam nebyl nalezen', value: '' }];
    }
    data = await parseDataForSelect(data, name);
    return data;
  };

  const promiseOptions = (inputValue: string): any =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  return { promiseOptions };
};
