import React from 'react' 
import { StyledListItem } from './styles'

interface ListItemProps {
    children: JSX.Element | JSX.Element[]
    className?: string
}

export const ListItem: React.FC<ListItemProps> = ({children, ...props}) => {
    return <StyledListItem {...props}>{children}</StyledListItem>
}