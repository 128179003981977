export const PAGINATION_PER_PAGE_LIMIT = 15;

export const STATE_OPTIONS = [
  { id: 'created', name: 'Založeno' },
  { id: 'canceled', name: 'Zrušená' },
  { id: 'refused', name: 'Odmítnuta' },
  { id: 'solved', name: 'Vyřešená' },
  { id: 'pending', name: 'V řešení' },
  { id: 'locked', name: 'Zamčená' }
];
