import {useState} from 'react' 

export const useHover = () => {
    const [ hide, setHide ] = useState<boolean> (false)

    const onHover = () => {
        setHide(prevState => (!prevState))
    }

    return {hide, onHover}
} 