import styled from 'styled-components'
import { Colors } from '../../../assets'

export const StyledWrapper = styled.div`
    width: 100%;
`

export const StyledLabel = styled.label`
    color: ${Colors.mainText}
`

export const StyledField = styled.input`
    border-radius: 0;
    box-shadow: none;
    appearance: none; 
    outline: none;
    height: 3rem; 
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-shadow: none;
    -webkit-border-radius: 0;  
    border: solid 1px ${Colors.disabledHover};


    &:focus {
        border-color: hsl(0, 0%, 70%);
    }

    &::placeholder {
        font-style: italic;
    }

    &:hover {
        border-color: hsl(0, 0%, 70%);
    }

    &:disabled {
        background: ${Colors.disabled};
    }

    &:disabled:hover {
        border: 1px solid ${Colors.disabledHover};
    }
  }
`