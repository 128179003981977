import { useEffect, useContext, useState } from 'react' 
import { useSigninForm } from './hooks/useSigninForm'
import { AuthContext } from '../../helpers/context_manager/AuthContext'
import { useLoginUser } from './hooks/useLoginUser'
import { useNavigate } from 'react-router'


export const useAuthentification = () => {
    const context = useContext( AuthContext ) 
    const [ authType, setAuthType ] = useState ({basic: false, kerberos: false, basicBtn: false})
    const { methods, fields, handleSubmit, errors} = useSigninForm ()
    const { loginKerberos } = useLoginUser ()
    const navigate = useNavigate ()


    useEffect (() => {
        logout()
        handleAuthentificationType ()
    },[])

    const logout = ():void => {
        context?.setAuthToken(null)
        localStorage.removeItem('user')
        localStorage.removeItem('authToken')
    }

    const handleAuthentificationType = () => {
        if (process.env.REACT_APP_ENABLE_KERBEROS === 'True' && process.env.REACT_APP_ENABLE_BASICAUTH === 'True') {
            //show btn to activate basic auth and allow kerberos signin
            setAuthType ({...authType, basic: false, kerberos: true, basicBtn: true})            
            //try kerberos login
            kerberosLogin ()
        } else if (process.env.REACT_APP_ENABLE_KERBEROS === 'True') {
            //allow kerberos authentification
            setAuthType ({...authType, kerberos: true})
            //try kerberos login
            kerberosLogin ()
        } else {
            //login user only with basic authentification
            setAuthType ({...authType, basic: true})
        }
    }

    const kerberosLogin = async () => {
        try {
            await loginKerberos ()
            navigate('/')
        }
        catch (e) {
            console.error(e)
            methods.setError('username', {message: e as string})
        }
    }   

    const enableBasicAuth = () => {
        setAuthType({...authType, basic: true, kerberos: false, basicBtn: false})
    }

    
    return { methods, fields, handleSubmit, errors, authType, enableBasicAuth}
}