import axios from 'axios';
import { header } from '../useApi';

export const SearchApi = () => {
  let cancel: any;
  const options = header();

  const liveSearch = async (endPoint: string, search: string, params: any) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/${endPoint}/`, {
        ...options,
        cancelToken: cancel.token,
        params: {
          search,
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { liveSearch };
};
