import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontWeight, Spacing } from '../../../assets/GlobalStyle/Variables';
import { Colors, FontSize } from '../../../assets';

type RichTextareaProps = {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  height?: string;
};

export const RichTextarea: FC<RichTextareaProps> = (props) => {
  const {
    id,
    name,
    label,
    placeholder = 'Text...',
    required = false,
    disabled = false,
    height = '300px'
  } = props;
  const methods = useFormContext();

  useEffect(() => {
    methods.register(name, { required: required });
  }, [name]);

  const handleChange = (editorState: string) => {
    methods.setValue(name, editorState);
  };

  const value = methods.watch(name);

  return (
    <StyledRichTextareWrapper height={height}>
      <StyledRichTextareaLabel htmlFor={id}>{label}</StyledRichTextareaLabel>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={disabled}
        {...props}
      />
    </StyledRichTextareWrapper>
  );
};

type StyledRichTextareWrapperProps = {
  height?: string;
};

const StyledRichTextareWrapper = styled.div<StyledRichTextareWrapperProps>`
  margin-bottom: ${Spacing(8)};

  .ql-editor {
    height: ${(props) => props.height} !important;
  }
`;

const StyledRichTextareaLabel = styled.label`
  margin-bottom: ${Spacing(8)};
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  white-space: nowrap;
  min-width: 200px;
`;
