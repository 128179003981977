import { FC, ReactNode } from 'react' 
import { useFormContext } from 'react-hook-form';
import { StyledWrapper, StyledLabel, StyledField} from './styles'

interface FiledProps {
    id: string; 
    name: string; 
    label?: ReactNode;
    placeholder?: string; 
    disabled?: boolean; 
    type?: string;
    fieldClassName?: string;
    wrapperClassName?: string;
    required?: boolean;
}

export const Field: FC<FiledProps> = (props) => {
    const {
        id, 
        name, 
        label, 
        placeholder, 
        type='text', 
        fieldClassName,
        wrapperClassName,
        required = false,
        disabled,
    } = props

    const methods = useFormContext()

    return ( 
        <StyledWrapper className={wrapperClassName}>
            <StyledLabel htmlFor={id}>{label}</StyledLabel>
            <StyledField
                type={type}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                {...methods.register(name, { required: required})}
                className={fieldClassName}
            />
        </StyledWrapper>
    )
}