import axios, { AxiosResponse } from 'axios';
import { header } from '../useApi';

export type User = {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    person: string;
};


export const UserApi = () => {
  
  const getUser = async (): Promise<AxiosResponse<User>> => {
    const options = header()
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/user/`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { getUser };
};
