import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

type FormValues = {
  date_from: string;
  date_to: string;
  doctor: string;
  requestType: string;
  requestState: string;
  clinic: string;
  format: string;
};

type Option = {
  value: string;
  label: string;
};

type SelectOptions = {
  clinics: Option[];
  requestType: Option[];
  requestState: Option[];
  format: Option[];
};

export const useReportsNew = () => {
  const methods = useForm({
    defaultValues: {
      date_from: "",
      date_to: "",
      doctor: "",
      requestType: "",
      requestState: "",
      clinic: "",
      format: "",
    },
  });

  const [options, setOptions] = useState<SelectOptions>({
    clinics: [
      { value: "option1", label: "option1" },
      { value: "option2", label: "option2" },
    ],
    requestType: [
      { value: "option1", label: "option1" },
      { value: "option2", label: "option2" },
    ],
    requestState: [
      { value: "option1", label: "option1" },
      { value: "option2", label: "option2" },
    ],
    format: [
      { value: "option1", label: "option1" },
      { value: "option2", label: "option2" },
    ],
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };

  const fields = {
    date_from: {
      id: "date_from",
      name: "date_from",
      label: "Časové období od",
    },
    date_to: {
      id: "date_to",
      name: "date_to",
      label: "Časové období do",
    },
    doctor: {
      id: "doctor",
      name: "doctor",
      label: "Lekář",
    },
    requestType: {
      id: "requestType",
      name: "requestType",
      label: "Typ žádanky",
      options: options.requestType,
    },
    requestState: {
      id: "requestState",
      name: "requestState",
      label: "Stav žádanky",
      options: options.requestState,
    },
    clinic: {
      id: "clinic",
      name: "clinic",
      label: "Klinika",
      options: options.clinics,
    },
    format: {
      id: "format",
      name: "format",
      label: "Formát",
      options: options.format,
    },
  };

  return { methods, fields, onSubmit };
};
