import { Row, Col } from 'react-bootstrap';
import { PageTitle } from '../../common';
import { RequestTransportListingTable } from './RequestTransportListingTable';

export const RequestTransportListing = () => {
  return (
    <>
      <PageTitle>Žádanky na přepravu</PageTitle>
      <Row>
        <Col>
          <RequestTransportListingTable />
        </Col>
      </Row>
    </>
  );
};
