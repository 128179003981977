import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { BarLoader } from 'react-spinners';
import styled from 'styled-components';
import { Colors, Spacing } from '../../assets/GlobalStyle/Variables';
import { Field, FileInput, AsyncSelectComponent, RichTextarea } from '../../common/components/Form';
import { PopUp } from '../../common/components/PopUp';
import { PrimaryButton, SecondaryButton } from '../../common/elements';
import { useRequestNew } from './useRequestNew';

export const RequestNewForm: FC = (): JSX.Element => {
  const {
    fields,
    methods,
    canSubmit,
    canLoadPatient,
    message,
    clinicWatch,
    loading,
    onSubmit,
    onLoadPatient,
    togglePopup,
    handleReset
  } = useRequestNew();
  return (
    <>
      <BarLoader
        loading={loading}
        color={Colors.lightBlue}
        cssOverride={{ position: 'fixed', width: '95%' }}
      />
      {message && <PopUp type={message.type} message={message.message} toggle={togglePopup} />}
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(canSubmit ? onSubmit : onLoadPatient)}>
          <Row>
            <Col lg={6} md={12}>
              <Field {...fields.patientExternalId} />
            </Col>
            <Col lg={6} md={12}>
              <Field {...fields.birth_number} />
            </Col>

            {canSubmit && (
              <>
                <Col lg={6} md={12}>
                  <Field {...fields.first_name} disabled={true} />
                </Col>
                <Col lg={6} md={12}>
                  <Field {...fields.last_name} disabled={true} />
                </Col>
                <Col lg={6} md={12}>
                  <AsyncSelectComponent {...fields.diagnoses} />
                </Col>
                <Col lg={6} md={12}>
                  <AsyncSelectComponent {...fields.clinic} />
                </Col>
                {clinicWatch && (
                  <Col lg={6} md={12}>
                    <AsyncSelectComponent
                      {...fields.department}
                      params={{ clinic: clinicWatch?.value, is_active: true }}
                    />
                  </Col>
                )}
                <Col lg={12}>
                  <RichTextarea {...fields.text} />
                </Col>
                <Col lg={12}>
                  <FileInput {...fields.file} />
                </Col>
              </>
            )}
          </Row>
          <StyledActionSection>
            {canSubmit && (
              <PrimaryButton type="submit" primary>
                Odeslat žádanku
              </PrimaryButton>
            )}
            {canLoadPatient && !canSubmit && (
              <PrimaryButton type="submit" primary>
                Vyhledat pacienta
              </PrimaryButton>
            )}
            {canSubmit && (
              <SecondaryButton type="button" onClick={handleReset}>
                Vymazat formulář
              </SecondaryButton>
            )}
          </StyledActionSection>
        </StyledForm>
      </FormProvider>
    </>
  );
};

const StyledActionSection = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing(20)};

  button {
    margin: 0 5px 0 5px;
  }
`;

const StyledForm = styled(Form)`
  padding-top: 1.5rem;
`;
