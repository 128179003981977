import { useEffect, useState } from 'react';
import {
  Requisition,
  RequisitionsGetParams,
  RequisitionsState,
  RequisitionsSubType,
  RequisitionsTypes
} from '../../api/requisitionsApi';
import { useApi } from '../../api/useApi';
import { PopUpType } from '../../common/components/PopUp';
import { PAGINATION_PER_PAGE_LIMIT, STATE_OPTIONS } from '../../helpers/constants';
import { dateHelper } from '../../helpers/help_functions/date_helpers';
import { BiSortAlt2 } from 'react-icons/bi';

export type RequisitionsTableType = {
  created_at: string;
  id: number;
  type: RequisitionsTypes;
  patient: {
    external_id: string;
    birth_number: string;
    name: string;
  };
  solver: string | null;
  clinic: string | null;
  department: string | null;
  applicant: string | null;
  state: string;
  text: string;
  file: string;
  subtype: RequisitionsSubType | null;
  answer: string | null;
};

type Options = {
  subtype: { id: number; name: string }[];
  state: { id: string; name: string }[];
  clinics: { id: number; name: string }[];
  departments: { id: number; name: string }[];
};

const applicant = [
  'applicant__name',
  'applicant__name__contains',
  'applicant__name__endswith',
  'applicant__name__startswith'
];
const date = ['date', 'date__max', 'date__min'];
const patientBirthNumber = [
  'patient__birth_number',
  'patient__birth_number__contains',
  'patient__birth_number__startswith',
  'patient__birth_number__endswith'
];
const patientExternalId = [
  'patient__external_id',
  'patient__external_id__contains',
  'patient__external_id__startswith',
  'patient__external_id__endswith'
];
const patientName = [
  'patient__name',
  'patient__name__contains',
  'patient__name__endswith',
  'patient__name__startswith'
];
const requestState = ['state'];
const requestId = ['id'];
const subtype = ['subtype'];
const clinic = ['clinic'];
const department = ['department'];

const defaultFiter = {
  ...applicant.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...date.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientBirthNumber.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientExternalId.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...requestState.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...requestId.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...subtype.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...clinic.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...department.reduce((a, v) => ({ ...a, [v]: undefined }), {})
};

export const useRequestListingTable = () => {
  const [params, setParams] = useState<RequisitionsGetParams>({
    offset: localStorage.getItem('request-list-page')
      ? Number(localStorage.getItem('request-list-page')) === 1
        ? 0
        : Number(localStorage.getItem('request-list-page')) * PAGINATION_PER_PAGE_LIMIT -
          PAGINATION_PER_PAGE_LIMIT
      : 0,
    page: localStorage.getItem('request-list-page')
      ? Number(localStorage.getItem('request-list-page'))
      : 1,
    limit: PAGINATION_PER_PAGE_LIMIT,
    o: localStorage.getItem('request-list-ordering') ?? '-created_at'
  });

  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<RequisitionsTableType[]>([]);
  const [message, setMessage] = useState<{ type: PopUpType; message: string } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<number | undefined>(undefined);

  const storageFilter = JSON.parse(localStorage.getItem('request-list-filter') as string);

  const [filter, setFilter] = useState<any>(storageFilter ?? defaultFiter);

  const [options, setOptions] = useState<Options>({
    subtype: [],
    state: STATE_OPTIONS,
    clinics: [],
    departments: []
  });

  const api = useApi();

  useEffect(() => {
    localStorage.setItem('request-list-filter', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    getRequests();
    getRequestSubtypeCodelist();
    getDepartments();
    getClinics();
  }, []);

  const handleDateChange = (event: string, field: string) => {
    resetTableFilterValues(field);

    setFilter((prevFilter: any) => ({
      ...prevFilter,
      [field]: event
    }));
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    resetTableFilterValues(name);

    setFilter((prevFilter: any) => ({
      ...prevFilter,
      [name]: value
    }));
  };

  const getRequests = async (
    offset: number = 0,
    page: number = 1,
    o: string | undefined = params.o
  ) => {
    setLoading(true);
    localStorage.setItem('request-list-page', page.toString());
    const response = await api.requisitions.get({
      ...params,
      offset,
      page,
      o,
      ...filterMapper(filter)
    });

    if (response.data.results.length === 0) {
      setLoading(false);
      setMessage({ type: 'success', message: 'Seznam žádanek je prázdny.' });
    }

    setData(dataFormatter(response.data.results));
    setCount(response.data.count);
    setParams({ ...params, page: response.data.page, offset: response.data.offset, o });

    setTimeout(() => {
      setLoading(false);
    }, 1050);
  };

  const getRequestSubtypeCodelist = async () => {
    const response = await api.requisitions.getRequisitionSubtypeCodeList({
      type: 'ipharm'
    });
    const subtype = response.data.results.map((item) => ({
      id: item.id,
      name: item.name
    }));
    setOptions((prevState) => ({ ...prevState, subtype }));
  };

  const getClinics = async () => {
    const response = await api.clinics.get({});
    const clinics = response.data.results.map((item) => ({ id: item.id, name: item.description }));
    setOptions((prevState) => ({ ...prevState, clinics }));
  };

  const getDepartments = async () => {
    const response = await api.departments.get({ is_active: true });
    const departments = response.data.results.map((item) => ({
      id: item.id,
      name: item.description
    }));
    setOptions((prevState) => ({ ...prevState, departments }));
  };

  const handleResetFilter = () => {
    localStorage.removeItem('request-list-filter');
    localStorage.removeItem('request-list-page');
    localStorage.removeItem('request-list-ordering');
    window.location.reload();
  };

  const handleOrdering = (orderingBy: string) => {
    getRequests(params.offset, params.page, getOrderingValue(orderingBy));
  };

  const getOrderingValue = (value: string): string => {
    if (value === params.o) {
      const orderingValue = value.includes('-') ? value : `-${value}`;
      localStorage.setItem('request-list-ordering', orderingValue);
      return orderingValue;
    }

    localStorage.setItem('request-list-ordering', value);
    return value;
  };

  const columns = [
    {
      label: 'Datum vzniku žádanky',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('created_at')} />
        </span>
      )
    },
    {
      label: 'ID žádanky',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('id')} />
        </span>
      )
    },
    // {
    //   label: 'Typ žádanky',
    //   order: (
    //     <span>
    //       <BiSortAlt2 onClick={() => handleOrdering('type')} />
    //     </span>
    //   )
    // },
    {
      label: 'ID pacienta',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('patient__external_id')} />
        </span>
      )
    },
    {
      label: 'RČ',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('patient__birth_number')} />
        </span>
      )
    },
    {
      label: 'Příjmení a jméno',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('patient__name')} />
        </span>
      )
    },
    {
      label: 'Žadatel',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('applicant__name')} />
        </span>
      )
    },
    {
      label: 'Klinika',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('clinic__description')} />
        </span>
      )
    },
    {
      label: 'Oddělení',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('department__description')} />
        </span>
      )
    },
    {
      label: 'Stav žádanky',
      order: (
        <span>
          <BiSortAlt2 onClick={() => handleOrdering('state')} />
        </span>
      )
    },
    { label: '' }
  ];

  const resetTableFilterValues = (name: string) => {
    if (applicant.some((item) => item === name)) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        applicant__name: undefined,
        applicant__name__contains: undefined,
        applicant__name__endswith: undefined,
        applicant__name__startswith: undefined
      }));
    }

    if (date.some((item) => item === name)) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        date: undefined,
        date__max: undefined,
        date__min: undefined
      }));
    }

    if (patientBirthNumber.some((item) => item === name)) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        patient__birth_number: undefined,
        patient__birth_number__contains: undefined,
        patient__birth_number__startswith: undefined,
        patient__birth_number__endswith: undefined
      }));
    }

    if (patientExternalId.some((item) => item === name)) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        patient__external_id: undefined,
        patient__external_id__contains: undefined,
        patient__external_id__startswith: undefined,
        patient__external_id__endswith: undefined
      }));
    }

    if (patientName.some((item) => item === name)) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        patient__name: undefined,
        patient__name__contains: undefined,
        patient__name__endswith: undefined,
        patient__name__startswith: undefined
      }));
    }
  };

  const translateRequisitionState = (state: RequisitionsState): string => {
    if (state === 'created') return 'Založeno';
    if (state === 'canceled') return 'Zrušená';
    if (state === 'refused') return 'Odmítnuta';
    if (state === 'solved') return 'Vyřešená';
    if (state === 'pending') return 'V řešení';
    if (state === 'locked') return 'Zamčená';
    return '';
  };

  const dataFormatter = (data: Requisition[]): RequisitionsTableType[] => {
    const fData = data.map((item) => ({
      created_at: dateHelper.formatDate(item.created_at),
      id: item.id,
      type: item.type,
      patient: {
        external_id: item.patient?.external_id,
        birth_number: item.patient?.birth_number,
        name: item.patient?.name
      },
      solver: item.solver?.name,
      state: translateRequisitionState(item.state),
      clinic: item.clinic?.description,
      department: item.department?.description,
      applicant: item.applicant?.name,
      text: item.text,
      file: item.file,
      subtype: item.subtype,
      answer: item.answer === 'null' || !item.answer ? '' : item.answer
    }));

    return fData;
  };

  const togglePopup = () => {
    setMessage(undefined);
  };

  return {
    columns,
    message,
    data,
    loading,
    detail,
    params,
    count,
    filter,
    options,
    togglePopup,
    translateRequisitionState,
    setDetail,
    getRequests,
    handleDateChange,
    handleFilterChange,
    handleResetFilter
  };
};

const filterMapper = (obj: any) => {
  const maped = obj;
  for (const key in maped) {
    if (
      maped[key] === undefined ||
      maped[key] === '' ||
      maped[key] === null ||
      maped[key] === 0 ||
      maped[key] === '0'
    ) {
      delete maped[key];
    }
  }

  if (maped.date) maped.date = dateHelper.parseDate(maped.date);
  if (maped.date__max) maped.date__max = dateHelper.parseDate(maped.date__max);
  if (maped.date__min) maped.date__min = dateHelper.parseDate(maped.date__min);

  return maped;
};
