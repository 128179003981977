import { AxiosRequestConfig } from 'axios';
import { ClinicsApi } from './clinicsApi';
import { DepartmentsApi } from './departmentsApi';
import { DiagnosesApi } from './diagnosesApi';
import { PatientsApi } from './patientsApi';
import { PersonsApi } from './personsApi';
import { ReportsApi } from './reportsApi';
import { RequisitionsApi } from './requisitionsApi';
import { SearchApi } from './searchApi';
import { TasksApi } from './tasksApi';
import { UserApi } from './userApi';
import { LoginApi } from './loginApi';
import { LocationsApi } from './locationsApi';

export type Pagination = {
  count: number;
  page: number;
  offset: number;
  next: string;
  previous: string;
};

export const header = (): AxiosRequestConfig => {
  const options: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('authToken')}`
    }
  };

  return options;
};

export const useApi = () => {
  const api = {
    requisitions: RequisitionsApi(),
    clinics: ClinicsApi(),
    departments: DepartmentsApi(),
    diagnoses: DiagnosesApi(),
    patients: PatientsApi(),
    persons: PersonsApi(),
    reports: ReportsApi(),
    tasks: TasksApi(),
    user: UserApi(),
    search: SearchApi(),
    login: LoginApi(),
    locations: LocationsApi()
  };

  return api;
};
