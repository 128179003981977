import React from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormProvider } from 'react-hook-form'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import { useAuthentification } from '../useAuthentification'
import { Field, PrimaryButton, Span, Link, PageTitle } from '../../../common'
import { Form, Row, Col } from 'react-bootstrap'

export const SignInForm: React.FC = () => {
    const { methods, fields, handleSubmit, errors, authType, enableBasicAuth} = useAuthentification ()    

    return (
        <>
        <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            {authType.basic &&
                <>
                <Row>
                    <Col className='d-flex'>
                    <Form.Group className='mb-3 d-flex align-items-center w-100' controlId='formUsername'>
                        <FontAwesomeIcon icon={faUser} color='#02385d' className='mr-4' size='xl'/>
                        <Field {...fields.username} placeholder='Login' required />
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex' >
                        <Form.Group className='mb-4 d-flex align-items-center w-100' controlId='fromPassword' >
                            <FontAwesomeIcon icon={faLock} color='#02385d' size='xl' className='mr-4' />
                            <Field {...fields.password} type='password' placeholder='Heslo' required /> 
                        </Form.Group>
                    </Col>
                </Row>       
                <Row>
                    <Col>
                        <Form.Group className='mb-2 text-center'>
                            {errors.username?.type == 'api' && <Span>{errors.username.message as string}</Span>}
                            {errors.username?.type == 'required' && <Span>Uživatelské jméno je povinné</Span>}
                            {errors.password?.type == 'required' && <Span>Heslo je povinné</Span>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center'>
                        <PrimaryButton className='mb-3' type='submit'>Přihlásit</PrimaryButton><br />
                    </Col>
                </Row>
            </>
            }
            { authType.kerberos && 
            <Row className='mb-5'>
                <Col className='text-center'>
                    <PageTitle>Probíhá přihlašování pomocí kerberos...</PageTitle>
                </Col>
            </Row>
            }
            {authType.basicBtn && 
                <Row>
                    <Col className='text-center'>
                        <PrimaryButton onClick={enableBasicAuth}>Přihlásit se pomocí jména a hesla</PrimaryButton>
                    </Col>
                </Row>
            }
            </Form>
            </FormProvider>
        </>
    )
}