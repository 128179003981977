import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Colors } from '../../../assets';
import { FontSize, FontWeight, Spacing } from '../../../assets/GlobalStyle/Variables';

type FieldProps = {
  id: string;
  name: string;
  label: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
};

export const Field: FC<FieldProps> = (props) => {
  const { id, name, placeholder, disabled = false, label, type = 'text', required = false } = props;

  const methods = useFormContext();

  return (
    <StyledFieldWrapper>
      <StyledLabel htmlFor={id}>{label}: </StyledLabel>
      <StyledInputWrapper>
        <StyledField
          type={type}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          {...methods.register(name, { required: required })}
        />
        {required && methods.formState.errors?.[name]?.type === 'required' && (
          <span role="alert">Povinní pole</span>
        )}
      </StyledInputWrapper>
    </StyledFieldWrapper>
  );
};

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: ${Spacing(8)};
`;

const StyledInputWrapper = styled.div`
  width: 100%;

  span {
    color: ${Colors.error};
    font-size: ${FontSize.normal};
  }
`;

const StyledField = styled.input`
  padding: 0 ${Spacing(8)};
  border: 1px solid ${Colors.disabledHover};
  line-height: 40px;
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.grey};
  font-size: ${FontSize.normal};

  &:focus {
    outline: none;
    border: 1px solid ${Colors.disabledHover};
  }
`;

const StyledLabel = styled.label`
  margin: 0;
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  white-space: nowrap;
  min-width: 200px;
`;
