import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PageTitle } from '../../common';
import { RequestListingTable } from './RequestListingTable';

export const RequestListing: React.FC = (): JSX.Element => {
  return (
    <>
      <PageTitle>Seznam pacientských žádanek</PageTitle>
      <Row>
        <Col>
          <RequestListingTable />
        </Col>
      </Row>
    </>
  );
};
