import axios, { AxiosResponse } from 'axios';
import { header, Pagination } from '../useApi';

type DiagnosesGetParams = {
  limit?: number;
  offset?: number;
  search?: string;
};

export type Diagnose = {
  id: number;
  code: string;
  name: string;
};

type DiagnosesGetResponse = Pagination & { 
  results: Diagnose[];
};

export const DiagnosesApi = () => {

  const options = header();
  
  const get = async (params: DiagnosesGetParams): Promise<AxiosResponse<DiagnosesGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/diagnoses`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: number): Promise<AxiosResponse<Diagnose>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/diagnoses/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById };
};
