import styled from 'styled-components'
import { Colors, FontSize } from '../../../assets'
import { Container, ContainerItem, Wrapper } from '../../elements'
import { GiHamburgerMenu } from 'react-icons/gi'
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc'

export const SidebarWrapper = styled(Wrapper)`
    background-color: ${Colors.lightBlue};
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.33);
    width: auto;
`
export const SidebarHeader = styled.div`
    background-color: ${Colors.black};
    display: flex;
    padding: 1rem 0.5rem 1rem 0.5rem; 
`
export const Icon = styled(GiHamburgerMenu)`
    font-size: ${FontSize.icon};
    color: ${Colors.white};
`

export const ArrowContainer = styled(Container)`
    align-items: center;
`

export const ArrowLeft = styled(VscTriangleLeft)`
    font-size: ${FontSize.smallIcon};
    color: ${Colors.white};
`

export const ArrowRight = styled(VscTriangleRight)`
    font-size: ${FontSize.smallIcon};
    color: ${Colors.white};
`
export const SidebarNavigationContainer = styled(Container)`
    flex: 1 1 90%;
    padding: 1rem;
    display: flex; 
    justify-content: flex-start;

    & a {
        font-size: ${FontSize.title}
    }

    & a:hover {
        color: ${Colors.orange}
    }

    & ul {
        display:flex; 
        flex-direction: column; 
        gap: 2rem;
    }
`

export const FullNavigation = styled(Container)`

    & li a {
        display: flex; 
        align-items: center;
    }

    & li a {
        display: flex; 
        gap: 1rem;
    }

    & li a:hover {
        color: ${Colors.orange}
    }

    & span {
        font-size: ${FontSize.normal};

    }
`

export const SidebarFooter = styled(ContainerItem)`
    flex: 1 1 5%; 
    dispaly: flex; 
    flex-direction: column; 
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;

    & span {
        font-size: ${FontSize.small};
        color: ${Colors.white};
    }
`