import { createGlobalStyle } from "styled-components";
import { Colors, FontSize, FontWeight, LineHeight } from "./Variables";

export const GlobalStyle = createGlobalStyle`
    html {
        margin:0; 
        padding:0; 
        min-height:100%; 
        overflow-x: hidden;
        font-family:  'Arial Nova','Arial', sans-serif;
    }    

    body {
        min-height:100%;
        width:100%;
        overflow-x: hidden;
        float:left;
        position:relative;
        margin:0px;
        padding:0px 0px 0 0px;
        outline: 0;
        box-sizing: border-box;
        font-family:  'Arial Nova','Arial', sans-serif !important; 
        font-weight:${FontWeight.normal};
        font-size: ${FontSize.normal};
        color: ${Colors.mainText};
        line-height: ${LineHeight.normal};
        background: ${Colors.white};
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: 100%;

    }

    input, button, textarea, select, optgroup {
        font-family:  'Arial Nova','Arial', sans-serif; 
    }

    h1, h2, h3, h4, h5 {
        margin: 0; 
        padding: 0; 
        font-weight: ${FontWeight.strong};
    }

    svg path {
        transition: all 0.3s ease;
    }

    ul, li, ol {
        padding: 0; 
        margin: 0;
    }
` 
