import { useState, useEffect, FC } from 'react';
import { StyledInput, StyledSelect, StyledTableTd } from './styles';

type TableFilterNumberFieldProps = {
  filter: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  field: string;
  type?: 'number' | 'text';
  onlyEqual?: boolean;
};

type Option = {
  value: string;
  label: string;
};

export const TableFilterNumberField: FC<TableFilterNumberFieldProps> = (props) => {
  const { filter, onChange, onSubmit, field, type = 'number', onlyEqual = false } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  let opt: Option[];

  if (onlyEqual) {
    opt = [{ value: equal, label: 'Rovná se' }];
  } else {
    opt = [
      { value: equal, label: 'Rovná se' },
      { value: min, label: 'Větší nebo rovná se' },
      { value: max, label: 'Menší nebo rovná se' }
    ];
  }

  const [option, setOption] = useState<Option>({
    value: equal,
    label: 'Rovná se'
  });
  const [value, setValue] = useState<string>();
  const [options] = useState<Option[]>(opt);

  useEffect(() => {
    if (filter[min]) {
      setValue(filter[min]);
      setOption(opt.find((item) => item.value === min) as Option);
    } else if (filter[max]) {
      setValue(filter[max]);
      setOption(opt.find((item) => item.value === max) as Option);
    } else if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal) as Option);
    } else {
      setValue('');
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[min]) {
      setOption(opt.find((item) => item.value === min) as Option);
    } else if (filter[max]) {
      setOption(opt.find((item) => item.value === max) as Option);
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal) as Option);
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option as Option);
    const oldEvent: any = {
      target: { value: value ? value : '', name: option?.value }
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <StyledTableTd role="cell">
      <StyledSelect name="age-select" onChange={handleChange}>
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? 'd-none' : ''}
            key={key}
            value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      <StyledInput
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </StyledTableTd>
  );
};
