import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Colors } from '../../assets';
import { SignInForm } from './components/SinginForm';
import { SigninCol } from './styles';

export const SignIn: React.FC = () => {
  return (
    <>
      <Container className="mt-5">
        <Row className="justify-content-center mt-5">
          <SigninCol lg={6} className={`p-5 bg-${Colors.lightBlue}`}>
            <SignInForm />
          </SigninCol>
        </Row>
      </Container>
    </>
  );
};
