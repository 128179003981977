import React, { useContext } from 'react' 
import { Container, Content, Logo, Navigation } from './styles'
import { Link, Span } from '../../elements'
import { Profile } from './Profile'
import { Help } from './Help'
import { dateHelper } from '../../../helpers/help_functions/date_helpers'
import { AuthContext } from '../../../helpers/context_manager/AuthContext'

export const Header: React.FC = () => {
    const context = useContext(AuthContext)
    return (
        <Container>
            <Content>
                <Logo>
                    <Link to='/'>iŽádanka</Link>
                    <Span>{`(${dateHelper.getCurrentDDMMYYDate()})`}</Span>
                </Logo>
                <Navigation>
                    <Help />
                    {context?.authToken && <Profile />}
                </Navigation>
            </Content>
        </Container>
        )
}