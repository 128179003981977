import React from 'react' 
import { Container, Row, Col } from 'react-bootstrap'
import { Icons } from './Icons'

export const IconBanner: React.FC = () => {
    return (
        <Container fluid className='p-5'>
            <Row className='p-3'>
                <Icons />
            </Row>
        </Container>
    )
    
}