import axios, { AxiosResponse } from 'axios';

export type LoginBasicPostType = {
  username: string;
  password: string;
};

export type LoginResponseType = {
  token: string;
};

export const LoginApi = () => {
  const basicLogin = async (
    data: LoginBasicPostType
  ): Promise<AxiosResponse<LoginBasicPostType>> => {
    const options = {
      headers: {
        Accept: 'application/json'
      }
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ROOT_API}/login_basic/`,
        { ...data },
        { ...options }
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const kerberosLogin = async (): Promise<any> => {
    try {
      const response = await fetch (process.env.REACT_APP_ROOT_API + '/login_kerberos/', {
        method: 'POST',
        credentials: 'include'
    });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { basicLogin, kerberosLogin };
};
