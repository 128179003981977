import React from 'react' 

interface ErrorPageInterface {
    title?: string 
}

export const ErrorPage:React.FC<ErrorPageInterface> = ({title}) => {
    return (
        <h1>Stránka {title} nebyla nalezena</h1>
    )
}