import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import styled from 'styled-components';
import { Colors } from '../../assets';
import { FontSize, Spacing } from '../../assets/GlobalStyle/Variables';
import { PrimaryButton, SecondaryButton } from '../../common';
import { Pagination } from '../../common/components/Pagination';
import { PopUp } from '../../common/components/PopUp';
import {
  TableFilterDate,
  TableFilterNumberField,
  TableFilterSelect,
  TableFilterTextField
} from '../../common/components/TableFilter';
import { RequestListingDetail } from './RequestListingDetail';
import { useRequestListingTable } from './useRequestListingTable';

export const RequestListingTable: FC = (): JSX.Element => {
  const {
    columns,
    loading,
    message,
    detail,
    params,
    count,
    data,
    filter,
    options,
    handleDateChange,
    handleFilterChange,
    togglePopup,
    setDetail,
    getRequests,
    handleResetFilter
  } = useRequestListingTable();

  return (
    <>
      <BarLoader
        loading={loading}
        color={Colors.lightBlue}
        cssOverride={{ position: 'fixed', width: '95%' }}
      />
      {message && <PopUp type={message.type} message={message.message} toggle={togglePopup} />}
      <StyledTable>
        <StyledTableHead>
          <tr>
            {columns.map((column, key) => (
              <th key={key}>
                <div>
                  {column.label} {column.order}
                </div>
              </th>
            ))}
          </tr>
        </StyledTableHead>
        <StyledTableBody>
          <tr>
            <TableFilterDate field="date" filter={filter} onChange={handleDateChange} />
            <TableFilterNumberField
              field="id"
              filter={filter}
              onChange={handleFilterChange}
              onSubmit={getRequests}
              onlyEqual={true}
            />
            {/* <TableFilterSelect
              field="subtype"
              filter={filter}
              onChange={handleFilterChange}
              options={options.subtype}
            /> */}
            <TableFilterTextField
              field="patient__external_id"
              filter={filter}
              onChange={handleFilterChange}
              onSubmit={getRequests}
            />
            <TableFilterTextField
              field="patient__birth_number"
              filter={filter}
              onChange={handleFilterChange}
              onSubmit={getRequests}
            />
            <TableFilterTextField
              field="patient__name"
              filter={filter}
              onChange={handleFilterChange}
              onSubmit={getRequests}
            />
            <TableFilterTextField
              field="applicant__name"
              filter={filter}
              onChange={handleFilterChange}
              onSubmit={getRequests}
            />
            <TableFilterSelect
              field="clinic"
              filter={filter}
              onChange={handleFilterChange}
              options={options.clinics}
            />
            <TableFilterSelect
              field="department"
              filter={filter}
              onChange={handleFilterChange}
              options={options.departments}
            />
            <TableFilterSelect
              field="state"
              filter={filter}
              onChange={handleFilterChange}
              options={options.state}
            />
            <StyledActionColumn>
              <PrimaryButton onClick={getRequests}>Vyhledat</PrimaryButton>
              <SecondaryButton onClick={handleResetFilter}>Resetovat filtr</SecondaryButton>
            </StyledActionColumn>
          </tr>
          {data?.map((item, key) => (
            <Fragment key={key}>
              <tr>
                <td>{item.created_at}</td>
                <td>{item.id}</td>
                {/* <td>{item.type}</td> */}
                <td>{item.patient.external_id}</td>
                <td>{item.patient.birth_number}</td>
                <td>{item.patient.name}</td>
                <td>{item.applicant}</td>
                <td>{item.clinic}</td>
                <td>{item.department}</td>
                <td>{item.state}</td>
                <StyledActionColumn>
                  {detail === key ? (
                    <PrimaryButton onClick={() => setDetail(undefined)}>Zavřít</PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={() => setDetail(key)}>Detail žádanky</PrimaryButton>
                  )}
                  <Link to={`/request-edit/${item.id}`}>
                    <SecondaryButton>Upravit žádanku</SecondaryButton>
                  </Link>
                </StyledActionColumn>
              </tr>
              {detail === key && (
                <tr>
                  <td colSpan={10}>
                    <RequestListingDetail request={item} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </StyledTableBody>
      </StyledTable>
      <StyledInfoBar>
        <i>Celkový počet: {count}</i>
      </StyledInfoBar>
      <Pagination
        count={count}
        offset={params.offset}
        page={params.page}
        limit={params.limit}
        onSubmit={getRequests}
      />
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  margin-top: ${Spacing(12)};
  font-size: 14px;
`;

const StyledTableHead = styled.thead`
  background-color: #cde0ee;
  border: 1px solid #cde0ee;
  height: 50px;
  vertical-align: top;

  th {
    border-right: 1px solid ${Colors.lightGrey};
    padding: ${Spacing(4)};

    div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }
  }
`;

const StyledTableBody = styled.tbody`
  border-left: 1px solid ${Colors.lightGrey};
  tr {
    height: 40px;
    border-bottom: 1px solid ${Colors.lightGrey};
  }

  td {
    border-right: 1px solid ${Colors.lightGrey};
    padding: ${Spacing(1)} ${Spacing(4)};
    &:nth-child(1) {
      width: 8%;
    }
    &:nth-child(2) {
      width: 6%;
      text-align: center;
    }
    &:nth-child(3) {
      text-align: center;
      width: 6%;
    }
    &:nth-child(4) {
      text-align: center;
      width: 6%;
    }
    &:nth-child(5) {
      text-align: center;
      width: 10%;
    }
    &:nth-child(6) {
      width: 16%;
    }
    &:nth-child(7) {
      width: 12%;
    }
    &:nth-child(8) {
      width: 16%;
    }
    &:nth-child(9) {
      text-align: center;
      width: 6%;
    }
  }

  button {
    font-size: 14px;
    padding: 0 4px;
    line-height: 30px;
  }
`;

const StyledActionColumn = styled.td`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 40px;

  button {
    line-height: 25px;
  }
`;

const StyledInfoBar = styled.div`
  margin-top: ${Spacing(4)};
  font-size: ${FontSize.small};
`;
