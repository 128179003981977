import { FC } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import { Spacing } from "../../../assets/GlobalStyle/Variables";
import { Field } from "../../../common/components/Form";
import { PrimaryButton } from "../../../common/elements";
import { useReportsNew } from "../useReports";

export const ReportsNewForm:FC = (): JSX.Element => {
  const { fields, methods, onSubmit } = useReportsNew();
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Field {...fields.date_from} />
          </div>
          <div className="col-lg-6 col-md-12">
            <Field {...fields.date_to} />
          </div>
          <div className="col-lg-6 col-md-12">
            {/* <Select {...fields.clinic} /> */}
          </div>
          <div className="col-lg-6 col-md-12">
            <Field {...fields.doctor} />
          </div>
          <div className="col-lg-6 col-md-12">
            {/* <Select {...fields.requestType} /> */}
          </div>
          <div className="col-lg-6 col-md-12">
            {/* <Select {...fields.requestState} /> */}
          </div>
          <div className="col-lg-6 col-md-12">
            {/* <Select {...fields.format} /> */}
          </div>
        </div>
        <StyledActionSection className="row">
          <PrimaryButton type="submit" primary>
            Filtrovat
          </PrimaryButton>
        </StyledActionSection>
      </form>
    </FormProvider>
  );
};

const StyledActionSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing(20)};
`;
