import styled from 'styled-components'
import { Col } from 'react-bootstrap'
import { Colors } from '../../assets'

export const Container = styled.div`
    display: flex; 
    flex-direction: column; 
    margin: 0 auto; 
    max-width: 30%; 
    padding: 1rem; 
`

export const SigninCol = styled(Col)`
    background-color: ${Colors.lightBackground};
    max-width: 420px;
`