import { FC, useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { Colors, FontSize, FontWeight, Spacing } from '../../../assets/GlobalStyle/Variables';

type Option = {
  value: string | number;
  label: string;
};

type ControlSelectProps = {
  name: string;
  label: string;
  id: string;
  options: Option[];
  isMulti?: boolean;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
};

export const ControlSelect: FC<ControlSelectProps> = (props) => {
  const {
    name,
    id,
    label,
    options,
    disabled = false,
    isMulti = false,
    placeholder = 'Vyberte...',
    required = false
  } = props;
  const methods = useFormContext();
  const selectStyles = {
    control: (props: any) => ({
      ...props,
      borderRadius: 0,
      height: 42,
      borderStyle: 'none',
      backgroundColor: 'none'
    })
  };

  const toggle = useWatch({ control: methods.control, name: name });

  useEffect(() => {
    if (!toggle) {
      clearFormValues();
    }
  }, [toggle]);

  const clearFormValues = () => {
    methods.setValue(name, []);
    methods.reset(methods.getValues(), { keepDefaultValues: true });
  };

  return (
    <Wrapper>
      <StyledLabel htmlFor={id}>{label}: </StyledLabel>
      <Controller
        name={name}
        control={methods.control}
        rules={{ required: required }}
        render={({ field }) => (
          <StyledSelectWrapper>
            <StyledControlSelect
              isClearable
              options={options}
              isDisabled={disabled}
              isMulti={isMulti ? true : false}
              styles={selectStyles}
              placeholder={placeholder}
              {...field}
            />
            {required && methods.formState.errors?.[name]?.type === 'required' && (
              <span role="alert">Povinné pole</span>
            )}
          </StyledSelectWrapper>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: ${Spacing(8)};
`;

const StyledSelectWrapper = styled.div`
  width: 100%;

  span {
    color: ${Colors.error};
    font-size: ${FontSize.small};
  }
`;

const StyledControlSelect = styled(Creatable)`
  margin: 0px;
  border: 1px solid ${Colors.disabledHover};
  line-height: 30px;
  height: 42px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.grey};
  font-size: ${FontSize.normal};

  &:focus {
    outline: none;
    border: 1px solid ${Colors.disabledHover};
  }
`;

const StyledLabel = styled.label`
  margin: 0;
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  white-space: nowrap;
  min-width: 200px;
`;
