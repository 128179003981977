import React from 'react' 
import { StyledContainerItem } from './styles'

interface Props {
    children: JSX.Element | JSX.Element[]; 
    className?: string; 
}

export const ContainerItem: React.FC<Props> = ({children, ...props}) => {
    return <StyledContainerItem {...props}>{children}</StyledContainerItem>
}