import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../assets';
import { PrimaryButton } from '../../elements';

export type PopUpType = 'success' | 'error' | 'info';

type PopUpProps = {
  message: ReactNode;
  toggle: () => void;
  type: PopUpType;
};

export const PopUp: FC<PopUpProps> = (props) => {
  const { message, toggle, type } = props;

  const handleClick = () => {
    toggle();
  };

  return (
    <StyledModal>
      <StyledModalContent type={type}>
        <StyledModalText>{message}</StyledModalText>

        <PrimaryButton type="button" className="button" onClick={handleClick}>
          OK
        </PrimaryButton>
      </StyledModalContent>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: block;
`;

type StyledModalContentProps = {
  type: PopUpType;
};

const StyledModalContent = styled.div<StyledModalContentProps>`
  text-align: center;
  padding: 30px;
  margin: 10em auto;
  background-color: ${(props) =>
    props.type === 'success'
      ? Colors.success
      : props.type === 'error'
      ? Colors.error
      : Colors.white};
  color: ${Colors.darkBlue};
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  width: 300px;
`;

const StyledModalText = styled.p`
  font-weight: bold;
`;
