import axios, { AxiosResponse } from 'axios';
import { header, Pagination } from '../useApi';

type PersonsGetParams = {
  limit?: number;
  offset?: number;
  search?: string;
};

export type Person = {
  id: number;
  person_number: string;
  name: string;
  f_title: string;
  l_title: string;
};

type PersonsGetResponse = Pagination & {
  results: Person[];
};

export const PersonsApi = () => {
  const options = header();

  const get = async (params?: PersonsGetParams): Promise<AxiosResponse<PersonsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/persons`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: number): Promise<AxiosResponse<Person>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/persons/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById };
};
