import styled from 'styled-components'

export const Layout = styled.div`
    display: flex; 
    min-height: 100vh; 
    width: 100%
`

export const Content = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
`

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 90%; 
    padding: 1rem;
    width: 100%;
`

export const MainWrapper = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
`