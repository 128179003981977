import axios, { AxiosResponse } from 'axios';
import { Pagination, header } from '../useApi';

export type Locations = {
  id: number;
  reference_id: number;
  external_id: string;
  name: string;
  valid_from: string;
  valid_to: string;
  is_active: boolean;
};

type LocationsCodeListResponse = Pagination & {
  results: Locations[];
};

export const LocationsApi = () => {
  const options = header();

  const get = async (): Promise<AxiosResponse<LocationsCodeListResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/locations/`, {
        ...options
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get };
};
