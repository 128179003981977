import { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../assets';
import { FontWeight, Spacing } from '../../assets/GlobalStyle/Variables';
import { RequisitionsTableType } from './useRequestListingTable';

type RequestListingDetailProps = {
  request: RequisitionsTableType;
};

export const RequestListingDetail: FC<RequestListingDetailProps> = (props) => {
  const { request } = props;
  return (
    <StyledDetailWrapper>
      <StyledDetailTable>
        <StyledDetailTableHead>
          <tr>
            <th>ID žádanky</th>
            <th>{request.id}</th>
          </tr>
        </StyledDetailTableHead>
        <StyledDetailTableBody>
          <tr>
            <td>Žadatel</td>
            <td>{request.applicant}</td>
          </tr>
          <tr>
            <td>Typ žádanky</td>
            <td>{request.type}</td>
          </tr>

          <tr>
            <td>Příloha</td>
            <td>
              {request.file ? (
                <a href={request.file} target="_blank" rel="noreferrer">
                  Zobrazit prílohu k žádance
                </a>
              ) : (
                '-'
              )}
            </td>
          </tr>
          {/* <tr>
            <td>Kategorie</td>
            <td>{request.subtype?.name}</td>
          </tr> */}
          <tr>
            <td>Text žádosti</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: request.text }}></div>
            </td>
          </tr>
          <tr>
            <td>Reakce klinického farmaceuta</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: request.answer as string }}></div>
            </td>
          </tr>
        </StyledDetailTableBody>
      </StyledDetailTable>
    </StyledDetailWrapper>
  );
};

const StyledDetailWrapper = styled.div`
  width: 80%;
  margin: ${Spacing(10)} auto;
`;

const StyledDetailTable = styled.table`
  width: 100%;
`;

const StyledDetailTableHead = styled.thead`
  background-color: ${Colors.lightBlue};
  color: ${Colors.white};
  height: 40px;
  vertical-align: middle;

  th {
    border-right: 1px solid ${Colors.lightGrey};
    padding: ${Spacing(4)};

    &:nth-child(1) {
      width: 300px;
      padding-left: ${Spacing(20)};
      border-left: 1px solid ${Colors.lightGrey};
    }

    &:nth-child(2) {
      padding-left: ${Spacing(20)};
    }
  }
`;

const StyledDetailTableBody = styled.tbody`
  td {
    &:nth-child(1) {
      padding-left: ${Spacing(20)};
      font-weight: ${FontWeight.strong};
      border-left: 1px solid ${Colors.lightGrey};
    }

    &:nth-child(2) {
      padding-left: ${Spacing(20)};
      padding-top: ${Spacing(4)};
      padding-bottom: ${Spacing(4)};
      text-align: left !important;
    }
  }
`;
