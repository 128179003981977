import React from 'react';
import { IconContainer, IconLink, Title, Circle } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { Colors } from '../../../assets/GlobalStyle/Variables';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

export const Icons: React.FC = () => {
  const icons: JSX.Element[] = [
    <>
      <IconContainer lg={1} key={1}>
        <IconLink to="/request-new">
          <Circle>
            <FontAwesomeIcon icon={faComments} size={'3x'} color={Colors.lightText} />
          </Circle>
          <Title>Žádanka na klinickou farmacii</Title>
        </IconLink>
      </IconContainer>

      <IconContainer lg={1} key={3}>
        <IconLink to="/">
          <Circle>
            <FontAwesomeIcon icon={faTruck} size={'3x'} color={Colors.lightText} />
          </Circle>
          <Title>Žádanka na přepravu</Title>
        </IconLink>
      </IconContainer>
    </>
  ];

  return <>{icons}</>;
};
