export const parseDataForSelect = async (data: any = [], name: string = '') => {
  if (data === null || data === undefined) return '';
  switch (name) {
    //   case "drug":
    //   case "drugs":
    //   case "narrow_therapeutic_window_drugs":
    //   case "high_interaction_potential_drugs":
    //     return parseDataForDrugsSelect(data);
    case 'department':
      return parseDataForDepartmentsSelect(data);
    case 'diagnoses':
      return parseDataForDiagnose(data);
    //   case "historyFilter":
    //     return parseOptionsForHistoryFilter(data);
    case 'clinic':
      return parseClinicsForSelect(data);
    //   case "reports":
    //     return parseReportsForSelect (data);
    //   case "tags":
    //     return parseDefaultDataForSelect(data);
    default:
      return parseDefaultDataForSelect(data);
  }
};

const parseClinicsForSelect = async (data = []) => {
  const options = data.map((item: any) => {
    return { label: item.description, value: item.id };
  });
  return options;
};

const parseDefaultDataForSelect = async (data = []) => {
  const options = data.map((item: any) => {
    return { label: item.name, value: item.id };
  });
  return options;
};

const parseDataForDiagnose = (data = []) => {
  if (!data.length)
    return [
      {
        label: 'Vyhledejte...',
        value: '',
        drugs: [{ label: 'Vyhledejte...', value: '' }]
      }
    ];
  else {
    return data.map((item: any) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  }
};

const parseDataForDepartmentsSelect = async (data = []) => {
  const options = data.map((item: any) => {
    return { label: item.description, value: item.id };
  });
  return options;
};
