import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Colors } from '../../../assets';
import { FontSize, FontWeight, Spacing } from '../../../assets/GlobalStyle/Variables';

type FileInputProps = {
  id: string;
  name: string;
  label: ReactNode;
  accept: string;
  acceptDescription: string;
  disabled?: boolean;
  type?: string;
  multiple?: boolean;
  fileUrl?: string;
};

export const FileInput: FC<FileInputProps> = (props) => {
  const {
    id,
    name,
    accept,
    acceptDescription,
    disabled = false,
    multiple = false,
    label,
    fileUrl
  } = props;

  const methods = useFormContext();

  return (
    <StyledWrapper>
      <StyledLabel htmlFor={id}>{label}: </StyledLabel>
      <StyledFileInputWrapper>
        <StyledFileInput
          type="file"
          id={id}
          disabled={disabled}
          accept={accept}
          multiple={multiple}
          {...methods.register(name)}
        />
        <StyledDescription>{acceptDescription}</StyledDescription>
        {fileUrl && (
          <a href={fileUrl} target="_blank" rel="noreferrer">
            Zobrazit soubor k žádance
          </a>
        )}
      </StyledFileInputWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Spacing(8)};
`;

const StyledFileInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  a {
    width: 100%;
    margin-top: ${Spacing(4)};
    color: ${Colors.lightBlue};
    font-weight: ${FontWeight.strong};
  }
`;

const StyledFileInput = styled.input`
  margin-left: 5px;
  line-height: 32px;
  box-sizing: border-box;
  color: ${Colors.grey};
  font-size: ${FontSize.normal};
`;

const StyledLabel = styled.label`
  margin: 0;
  color: ${Colors.grey};
  font-weight: ${FontWeight.strong};
  font-size: ${FontSize.normal};
  white-space: nowrap;
  min-width: 200px;
`;

const StyledDescription = styled.i`
  color: ${Colors.grey};
  font-size: ${FontSize.small};

  @media (max-width: 991px) {
    margin-top: ${Spacing(2)};
  }
`;
