import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageTitle } from '../../common';
import { RequestTransportEditForm } from './RequestTransportEditForm';

export const RequestTransportEdit: React.FC = () => {
  const [requestId, setRequestId] = useState<string | undefined>();
  return (
    <>
      <PageTitle>Editace žádanky na přepravu / ID žádanky - {requestId}</PageTitle>
      <Row>
        <Col xl={8} lg={10} md={12}>
          <RequestTransportEditForm setRequestId={setRequestId} />
        </Col>
      </Row>
    </>
  );
};
