import styled from 'styled-components';
import { Colors } from '../../../assets';

export const StyledTableTd = styled.td`
  padding: 0 !important;
  vertical-align: top;
`;

export const StyledSelect = styled.select`
  margin: 0px;
  padding: 0px 15px;
  border: none;
  border-bottom: 1px solid ${Colors.lightGrey};
  line-height: 20px;
  height: 22px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.mainText};
  font-size: 12px;

  &:focus {
    outline: none;
  }
`;

export const StyledInput = styled.input`
  margin: 0px;
  padding: 0px 15px;
  border: none;
  line-height: 20px;
  height: 22px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.mainText};
  font-size: 12px;

  &:focus {
    outline: none;
  }
`;
