const getCurrentDate = (): Object => {
  return new Date();
};

const getCurrentDDMMYYDate = (): string => {
  const date: Date = new Date();
  let [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];

  const sMonth: string = month < 10 ? `0${month}` : `${month}`;
  const sDay: string = day < 10 ? `0${day}` : `${day}`;
  return `${sDay}.${sMonth}.${year}`;
};

const formatDate = (value: Date | string): string => {
  if (value === '') return 'Žádné datum';
  const date = new Date(value);
  const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
  return `${day}.${month}.${year}`;
};

const parseDate = (data: string): string => {
  const date = new Date(data);
  let [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
  const newMonth = month < 10 ? `0${month}` : month;
  const newDay = day < 10 ? `0${day}` : day;
  return `${year}-${newMonth}-${newDay}`;
};

export const dateHelper = {
  getCurrentDate,
  getCurrentDDMMYYDate,
  formatDate,
  parseDate
};
