import { NavLink } from "react-router-dom";
import styled from 'styled-components'
import { Colors } from "../../../assets";

export const StyledLink = styled(NavLink)`
    text-decoration: none; 
    color: ${Colors.white};     
    transition: all 0.3s ease;

    &:hover {
        text-decoration: none; 
        ${Colors.orange};
    }
`