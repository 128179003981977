import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../auth/PrivateRoute/PrivateRoute';
import {
  Home,
  ErrorPage,
  SignIn,
  RequestNew,
  RequestEdit,
  RequestListing,
  Help,
  Settings,
  Reports,
  RequestTransportNew,
  RequestTransportListing,
  RequestTransportEdit
} from '../../pages';

const router: JSX.Element[] = [
  <Route
    key={1}
    path="/"
    element={<PrivateRoute component={<Home />} />}
    errorElement={<ErrorPage />}
  />,
  <Route key={2} path="/signin" element={<SignIn />} errorElement={<ErrorPage />} />,
  <Route
    key={3}
    path="/help"
    element={<PrivateRoute component={<Help />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={4}
    path="/settings"
    element={<PrivateRoute component={<Settings />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={5}
    path="/request-listing"
    element={<PrivateRoute component={<RequestListing />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={10}
    path="/request-transport-listing"
    element={<PrivateRoute component={<RequestTransportListing />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={6}
    path="/request-new"
    element={<PrivateRoute component={<RequestNew />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={9}
    path="/request-transport-new"
    element={<PrivateRoute component={<RequestTransportNew />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={7}
    path="/request-edit/:id"
    element={<PrivateRoute component={<RequestEdit />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={11}
    path="/request-transport-edit/:id"
    element={<PrivateRoute component={<RequestTransportEdit />} />}
    errorElement={<ErrorPage />}
  />,
  <Route
    key={8}
    path="/reports"
    element={<PrivateRoute component={<Reports />} />}
    errorElement={<ErrorPage />}
  />
];

export const RouterManager: React.FC = () => {
  return <Routes>{router}</Routes>;
};
