import React from 'react' 
import { Navigation } from './components/Navigation/Navigation'
import { Footer } from './components/Footer/Footer'
import { HeaderIcon } from './components/HeaderIcon/HeaderIcon'
import { SidebarFooter, SidebarHeader, SidebarWrapper, SidebarNavigationContainer } from './styles'
import { SidebarLogic } from './SidebarLogic'

export const Sidebar: React.FC = () => {
    const {hide, toggle} = SidebarLogic ()
    return (
        <>
        <SidebarWrapper flexColumn>
            <SidebarHeader>
                <HeaderIcon hide={hide} toggle={toggle}/>
            </SidebarHeader>
            <SidebarNavigationContainer>
                <Navigation hide={hide}/>
            </SidebarNavigationContainer>
            <SidebarFooter>
                <Footer />
            </SidebarFooter>
        </SidebarWrapper>
        </>
    )
}