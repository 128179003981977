import axios, { AxiosResponse } from 'axios';
import { header } from '../useApi';

export type Task = {
  state: string;
  result: any;
};

export const TasksApi = () => {

  const options = header();
  
  const getOneById = async (task_id: string): Promise<AxiosResponse<Task>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/tasks/${task_id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  return { getOneById };
};
