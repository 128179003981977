import React from 'react'
import { Navigate }  from 'react-router'

interface RouteInterface {    
    component: JSX.Element,
}

// Create a component which check if user is login 
// It is Route component -> user loged in it can access the route if not then no
export const PrivateRoute: React.FC<RouteInterface> = ( {component} ) => {
    return (
        <>  
            {localStorage.getItem('authToken') ? component : <Navigate to= '/signin/' />}
        </>
        )
    }

