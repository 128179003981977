import axios, { AxiosResponse } from 'axios';
import { Insurance } from '../insurancesApi';
import { header, Pagination } from '../useApi';

type PatientsGetParams = {
  limit?: number;
  offset?: number;
};

type PatientGetHistoryParams = {
  from?: string;
  to?: string;
  id: number;
};

export type Patient = {
  id: number | string;
  birth_number: string;
  birth_date: string;
  external_id: string;
  name: string;
  first_name: string;
  last_name: string;
  insurance_company: Insurance;
  insurance_number: string;
};

type PatientsGetResponse = Pagination & {
  results: Patient[];
};

type PatientsPostParams = {
  birth_number?: string;
  external_id?: string;
};

type PatientsPostResponse = {
  task_id: string;
};

export const PatientsApi = () => {

  const options = header();
  
  const get = async (params: PatientsGetParams): Promise<AxiosResponse<PatientsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/patients`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: number): Promise<AxiosResponse<Patient>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/patients/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getHistory = async (
    id: number,
    params: PatientGetHistoryParams
  ): Promise<AxiosResponse<any>> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_API}/patients/${id}/history/`,
        {
          ...options,
          params: {
            ...params
          }
        }
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const post = async (params: PatientsPostParams): Promise<AxiosResponse<PatientsPostResponse>> => {
    let url = '';
    if (params.birth_number) {
      url = `${process.env.REACT_APP_ROOT_API}/patients/load-patient/?birth_number=${params.birth_number}`;
    }

    if (params.external_id) {
      url = `${process.env.REACT_APP_ROOT_API}/patients/load-patient/?external_id=${params.external_id}`;
    }

    try {
      const response = await axios.post(
        url, {}, {...options});
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById, post, getHistory };
};
