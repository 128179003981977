import { FC } from 'react';
import styled from 'styled-components';
import { Colors, Spacing } from '../../../assets/GlobalStyle/Variables';
import { PAGINATION_PER_PAGE_LIMIT } from '../../../helpers/constants';
import { PrimaryButton } from '../../elements';

type PaginationProps = {
  count: number;
  page: number;
  offset: number;
  limit?: number;
  onSubmit: (offset: number, page: number) => void;
};

export const Pagination: FC<PaginationProps> = (props) => {
  const { onSubmit, count, page, offset, limit = PAGINATION_PER_PAGE_LIMIT } = props;
  const countPages = Math.ceil(count / limit);

  const arrayPages: React.ReactElement[] = [];
  let pageCount = 3;

  const getPages = (): React.ReactElement[] | null => {
    if (countPages === 0) {
      return null;
    }

    // if (page > 3) {
    //   arrayPages.push(
    //     <StyledPaginationButton
    //       key="first"
    //       className="button pagination-button mr-2"
    //       onClick={() => onSubmit(0, 1)}>
    //       První
    //     </StyledPaginationButton>
    //   );
    // }

    if (page !== 1) {
      arrayPages.push(
        <StyledPaginationButton
          key="previous"
          className="button pagination-button mr-2"
          onClick={() => onSubmit(offset - limit, page - 1)}>
          Zpět
        </StyledPaginationButton>
      );
    }

    // for (let i = 1; i <= countPages; i++) {
    //   let offset = (i - 1) * limit;
    //   if (Math.abs(i - page) < pageCount) {
    //     arrayPages.push(
    //       <StyledPaginationButton
    //         className="button pagination-button mr-2"
    //         disabled={i === page}
    //         key={i}
    //         onClick={() => onSubmit(offset, i)}>
    //         {i.toString()}
    //       </StyledPaginationButton>
    //     );
    //   }
    // }

    for (let i = 1; i <= countPages; i++) {
      let offset = (i - 1) * limit;

      arrayPages.push(
        <StyledPaginationButton
          className="button pagination-button mr-2"
          disabled={i === page}
          key={i}
          onClick={() => onSubmit(offset, i)}>
          {i.toString()}
        </StyledPaginationButton>
      );
    }

    if (countPages !== page) {
      arrayPages.push(
        <StyledPaginationButton
          key="next"
          className="button pagination-button mr-2"
          onClick={() => onSubmit(offset + limit, page + 1)}>
          Další
        </StyledPaginationButton>
      );
    }

    // if (page !== countPages) {
    //   arrayPages.push(
    //     <StyledPaginationButton
    //       key="last"
    //       className="button pagination-button mr-2"
    //       onClick={() => onSubmit(countPages * limit - limit, countPages)}>
    //       Poslední
    //     </StyledPaginationButton>
    //   );
    // }
    return arrayPages;
  };

  return <StyledPagination>{getPages()}</StyledPagination>;
};
const StyledPagination = styled.div`
  width: 100%;
  margin-top: ${Spacing(11)};
`;

const StyledPaginationButton = styled(PrimaryButton)`
  min-width: auto;

  &:disabled {
    background-color: ${Colors.disabledHover};
    border: none;
    cursor: default;
    pointer-events: none;
  }
`;
