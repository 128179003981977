import { FC, useState, useEffect } from 'react';
import { StyledInput, StyledSelect, StyledTableTd } from './styles';

type TableFilterTextFieldProps = {
  filter: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  field: string;
  type?: 'number' | 'text';
};

type Option = {
  value: string;
  label: string;
};

export const TableFilterTextField: FC<TableFilterTextFieldProps> = (props) => {
  const { filter, onChange, onSubmit, field, type = 'text' } = props;

  const equal = field;
  const contains = `${field}__contains`;
  const startswith = `${field}__startswith`;
  const endswith = `${field}__endswith`;

  const opt = [
    { value: contains, label: 'Obsahuje' },
    { value: startswith, label: 'Začíná na' },
    { value: endswith, label: 'Končí na' },
    { value: equal, label: 'Rovná se' }
  ];

  const [option, setOption] = useState<Option>({
    value: contains,
    label: 'Obsahuje'
  });
  const [value, setValue] = useState<string>();
  const [options] = useState<Option[]>(opt);

  useEffect(() => {
    if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal) as Option);
    } else if (filter[startswith]) {
      setValue(filter[startswith]);
      setOption(opt.find((item) => item.value === startswith) as Option);
    } else if (filter[endswith]) {
      setValue(filter[endswith]);
      setOption(opt.find((item) => item.value === endswith) as Option);
    } else if (filter[contains]) {
      setValue(filter[contains]);
      setOption(opt.find((item) => item.value === contains) as Option);
    } else {
      setValue('');
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal) as Option);
    } else if (filter[startswith]) {
      setOption(opt.find((item) => item.value === startswith) as Option);
    } else if (filter[endswith]) {
      setOption(opt.find((item) => item.value === endswith) as Option);
    } else if (filter[contains]) {
      setOption(opt.find((item) => item.value === contains) as Option);
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option as Option);
    const oldEvent: any = {
      target: { value: value ? value : '', name: option?.value as string }
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <StyledTableTd role="cell" className="td-filter">
      <StyledSelect className="table-filter-select" name="select" onChange={handleChange}>
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? 'd-none' : ''}
            key={key}
            value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      <StyledInput
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </StyledTableTd>
  );
};
