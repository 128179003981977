import { FC, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { cs } from 'date-fns/esm/locale';
import { StyledSelect, StyledTableTd } from './styles';
import styled from 'styled-components';
import { Colors } from '../../../assets';

type TableFilterDateProps = {
  filter: any;
  onChange: (event: any, field: string) => void;
  field: string;
};

type Option = {
  value: string;
  label: string;
};

export const TableFilterDate: FC<TableFilterDateProps> = (props) => {
  const { filter, onChange, field } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  const opt = [
    { value: equal, label: 'Rovná se' },
    { value: max, label: 'Menší nebo rovná se' },
    { value: min, label: 'Větší nebo rovná se' }
  ];

  const [option, setOption] = useState<Option>({
    value: equal,
    label: 'Rovná se'
  });
  const [value, setValue] = useState<Date | undefined>(new Date());
  const [options] = useState<Option[]>(opt);

  useEffect(() => {
    if (filter[max]) {
      setValue(new Date(filter[max]));
      setOption(opt.find((item) => item.value === max) as Option);
    } else if (filter[min]) {
      setValue(new Date(filter[min]));
      setOption(opt.find((item) => item.value === min) as Option);
    } else if (filter[equal]) {
      setValue(new Date(filter[equal]));
      setOption(opt.find((item) => item.value === equal) as Option);
    } else {
      setValue(undefined);
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[max]) {
      setOption(opt.find((item) => item.value === max) as Option);
    } else if (filter[min]) {
      setOption(opt.find((item) => item.value === min) as Option);
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal) as Option);
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option as Option);
    onChange(value ? value : '', option?.value as string);
  };

  registerLocale('cz', cs);

  return (
    <StyledTableTd role="cell" className="td-filter">
      <StyledSelect className="table-filter-select" name="age-select" onChange={handleChange}>
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? 'd-none' : ''}
            key={key}
            value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      <StyledReactDatePicker
        dateFormat="dd.MM.yyyy"
        calendarStartDay={1}
        placeholderText="dd.mm.rrrr"
        onChange={(event) => onChange(event, option.value)}
        selected={value}
        locale="cz"
        isClearable={false}
        name={option.value}
      />
    </StyledTableTd>
  );
};

const StyledReactDatePicker = styled(ReactDatePicker)`
  margin: 0px;
  padding: 0px 15px;
  border: none;
  line-height: 20px;
  height: 22px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  color: ${Colors.mainText};
  font-size: 12px;

  &:focus {
    outline: none;
  }
`;
