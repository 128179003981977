import axios, { AxiosResponse } from 'axios';
import { header, Pagination } from '../useApi';

type ClinicsGetParams = {
  clinic_filter?: 'hospitals' | 'abmulances' | 'my_clinics' | 'my_ambulances' | 'all';
  alias?: string;
  limit?: number;
  offset?: number;
};

export type Clinic = {
  id: number;
  patient_count: number;
  patient_without_checkin_count: number;
  external_id: number;
  reference_id: number;
  abbreviation: string;
  description: string;
  is_hospital: boolean;
  is_ambulance: boolean;
  image: string;
  alias: string;
};

type ClinicsGetResponse = Pagination & {
  results: Clinic[];
};

export const ClinicsApi = () => {
  const options = header();

  const get = async (params: ClinicsGetParams): Promise<AxiosResponse<ClinicsGetResponse>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/clinics`, {
        ...options,
        params: {
          ...params
        }
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneById = async (id: number): Promise<AxiosResponse<Clinic>> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_API}/clinics/${id}`, {
        ...options
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOneByAlias = async (alias: string): Promise<AxiosResponse<ClinicsGetResponse>> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_API}/clinics/?alias=${alias}`,
        {
          ...options
        }
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { get, getOneById, getOneByAlias };
};
