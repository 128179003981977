import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useApi } from '../../api/useApi';
import { PopUpType } from '../../common/components/PopUp';
import { RequisitionsPostType } from '../../api/requisitionsApi';

type FormValues = {
  subtype: Option;
  end: Option;
  text: string;
};

type Option = {
  value: string | number;
  label: string;
};

type Options = {
  transportType: Option[];
  transportDestination: Option[];
};

type UseRequestEditProps = {
  id: string | undefined;
};

export const useRequestTransportEdit = (props: UseRequestEditProps) => {
  const { id } = props;
  const [message, setMessage] = useState<{ type: PopUpType; message: string } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [options, setOptions] = useState<Options>({ transportDestination: [], transportType: [] });

  const api = useApi();

  useEffect(() => {
    onLoadData();
    getRequisitionType();
    getLocation();
  }, [id]);

  const methods = useForm({
    defaultValues: {
      subtype: { label: '', value: '' },
      end: { label: '', value: '' },
      text: '',
      answer: ''
    }
  });

  const getRequisitionType = async () => {
    const response = await api.requisitions.getRequisitionSubtypeCodeList({ type: 'idoprava' });
    if (response.status === 200) {
      setOptions((prev) => ({
        ...prev,
        transportType: response.data.results.map((item) => ({ label: item.name, value: item.id }))
      }));
    }
  };

  const getLocation = async () => {
    const response = await api.locations.get();
    if (response.status === 200) {
      setOptions((prev) => ({
        ...prev,
        transportDestination: response.data.results.map((item) => ({
          label: item.name,
          value: item.id
        }))
      }));
    }
  };

  const onLoadData = async () => {
    setLoading(true);

    try {
      const response = await api.requisitions.getOneById(id as string);

      if (response.status === 200) {
        const data = response.data;
        methods.setValue('subtype', {
          value: data.subtype?.id.toString() as string,
          label: data.subtype?.name as string
        });
        methods.setValue('end', {
          value: data.end?.id.toString() as string,
          label: data.end?.name as string
        });
        methods.setValue('text', data.text);
        methods.setValue('answer', data.answer);

        setCanEdit(data.state !== 'locked' && data.state !== 'solved' && data.state !== 'refused');
        setTimeout(() => {
          setLoading(false);
        }, 1050);
      }
    } catch (error) {
      setLoading(false);
      setMessage({ type: 'error', message: 'Žádanka nebyla nalezena.' });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    const applicant = await api.user.getUser();

    const mData: RequisitionsPostType = {
      ...data,
      applicant: applicant.data.id,
      subtype: data.subtype.value,
      end: data.end.value
    };
    try {
      const response = await api.requisitions.update(mData, id as string);

      if (response.status !== 201) {
        setMessage({ type: 'error', message: 'Žádanku se nepodařilo vytvořit.' });
        setLoading(false);
      }

      setLoading(false);
      setMessage({ type: 'success', message: 'Žádanka byla úspěšně uložena.' });
    } catch (error) {
      setMessage({
        type: 'error',
        message: 'Žádanku se nepodařilo uložit, zkuste to později, prosím.'
      });
      setLoading(false);
    }
  };

  const fields = {
    subtype: {
      id: 'subtype',
      name: 'subtype',
      label: 'Typ přepravy'
    },
    end: {
      id: 'end',
      name: 'end',
      label: 'Cíl přepravy'
    },
    text: {
      id: 'text',
      name: 'text',
      label: 'Text žádosti'
    }
  };

  const togglePopup = () => {
    setMessage(undefined);
  };

  return {
    loading,
    message,
    methods,
    fields,
    canEdit,
    options,
    togglePopup,
    onSubmit
  };
};
